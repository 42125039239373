import { PhoneCall } from "lucide-react";
import GeneralMessagesIcon from "../../ui/components/icons/general-messages-icon";
import OtherMessagesIcon from "../../ui/components/icons/other-messages-icon";
import ProblemSolvingIcon from "../../ui/components/icons/problem-solving-icon";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/components/ui/accordion";
import { Button } from "../../ui/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/components/ui/tabs";

const BestPractices = () => {
  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex justify-between">
        <div>
          <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">7i7 Leitfaden</h1>
          <p className="text-[16px] text-[#888]">Ansätze & Hilfe für deinen Alltag</p>
        </div>
        <Button className="flex gap-4">
          <PhoneCall />
          <span>+49 4551 890 3120</span>
        </Button>
      </div>

      <Tabs defaultValue="general" className="w-full">
        <TabsList className="gap-4">
          <TabsTrigger value="general">
            <GeneralMessagesIcon />
            Allgemeine Nachrichten
          </TabsTrigger>
          <TabsTrigger value="problem-solving">
            <ProblemSolvingIcon />
            Problemlösungen
          </TabsTrigger>
          <TabsTrigger value="other-solutions">
            <OtherMessagesIcon />
            Sonstige Nachrichten
          </TabsTrigger>
        </TabsList>
        <TabsContent value="general">
          <GeneralMessagesTab />
        </TabsContent>
        <TabsContent value="problem-solving">
          <GeneralMessagesTab />
        </TabsContent>
        <TabsContent value="other-solutions">
          <GeneralMessagesTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const GeneralMessagesTab = () => {
  return (
    <Accordion type="single" defaultValue="item-1" collapsible className="w-full gap-3 flex flex-col mt-12">
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>How can I join your team?</p>
            <span className="flex items-center justify-center text-sm rounded-[48px] border border-[#D0D5DD] h-9 w-32 transition-colors">
              Abrechnung
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          Nutze die Auftragsbeschreibung, um Deinen Auftrag so detailliert wie möglich zu formulieren. Wichtige Elemente
          hierbei sind die benötigten Formate, Inspirationsquellen, eventuelle Druckanbieter, sowie Texte und
          Informationen über die Zielgruppe. Solltest Du Unterstützung bei der Erstellung Deines Auftrages benötigen,
          stehen wir Dir gerne zur Verfügung.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>FAQ 2</p>
            <span className="flex items-center justify-center text-sm rounded-[48px] border border-[#D0D5DD] h-9 w-32 transition-colors">
              Design
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          Nutze die Auftragsbeschreibung, um Deinen Auftrag so detailliert wie möglich zu formulieren. Wichtige Elemente
          hierbei sind die benötigten Formate, Inspirationsquellen, eventuelle Druckanbieter, sowie Texte und
          Informationen über die Zielgruppe. Solltest Du Unterstützung bei der Erstellung Deines Auftrages benötigen,
          stehen wir Dir gerne zur Verfügung.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>FAQ 3</p>
            <span className="flex items-center justify-center text-sm rounded-[48px] border border-[#D0D5DD] h-9 w-32 transition-colors">
              Abrechnung
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          Nutze die Auftragsbeschreibung, um Deinen Auftrag so detailliert wie möglich zu formulieren. Wichtige Elemente
          hierbei sind die benötigten Formate, Inspirationsquellen, eventuelle Druckanbieter, sowie Texte und
          Informationen über die Zielgruppe. Solltest Du Unterstützung bei der Erstellung Deines Auftrages benötigen,
          stehen wir Dir gerne zur Verfügung.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-4">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>FAQ 4</p>
            <span className="flex items-center justify-center text-sm rounded-[48px] border border-[#D0D5DD] h-9 w-32 transition-colors">
              Abrechnung
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          Nutze die Auftragsbeschreibung, um Deinen Auftrag so detailliert wie möglich zu formulieren. Wichtige Elemente
          hierbei sind die benötigten Formate, Inspirationsquellen, eventuelle Druckanbieter, sowie Texte und
          Informationen über die Zielgruppe. Solltest Du Unterstützung bei der Erstellung Deines Auftrages benötigen,
          stehen wir Dir gerne zur Verfügung.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BestPractices;
