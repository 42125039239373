const OrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="none">
      <g stroke="currentColor">
        <path
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M12.61 3.897h3.724C17.8 3.897 19 4.769 19 5.835V20.81c0 1.066-1.2 1.938-2.666 1.938H3.666C2.2 22.747 1 21.875 1 20.81V5.835C1 4.77 2.2 3.897 3.666 3.897h3.847"
        />
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.93 19.256h10.14" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 1c1.373 0 2.487.81 2.487 1.808v2.387H7.514V2.808C7.514 1.81 8.628 1 10 1Z"
        />
        <path
          strokeMiterlimit="10"
          d="M13.578 15.253c-1.3-.671-1.864-1.203-2.109-1.54a.475.475 0 0 1 .05-.624c.226-.231.519-.608.394-.925-.115-.291.075-.653.252-.901a1.5 1.5 0 0 0 .289-.854c.007-.85-.26-2.366-2.271-2.408a4.24 4.24 0 0 0-.183 0h-.183c-2.01.042-2.278 1.558-2.27 2.408a1.5 1.5 0 0 0 .288.854c.177.248.367.61.252.901-.125.317.168.694.395.925.17.174.19.429.049.623-.245.338-.81.87-2.109 1.54C4.109 16.448 5.314 17 5.314 17h9.372s1.205-.553-1.108-1.747Z"
        />
      </g>
    </svg>
  );
};

export default OrdersIcon;
