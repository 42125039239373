const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" fill="none">
      <g stroke="currentColor" strokeWidth="2">
        <path strokeMiterlimit="10" d="M10.5 13.133A6.066 6.066 0 1 0 10.5 1a6.066 6.066 0 0 0 0 12.133Z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 22.633a9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.5 9.5M5.724 14.42l4.776 3.902M15.239 14.398 10.5 18.322"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
