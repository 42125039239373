const ContractIcon = () => {
  return (
    <svg width={13} height={15} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.47059 6.85H3.58824M4.88235 9.45H3.58824M8.76471 4.25H3.58824M11.3529 7.5V4.12C11.3529 3.0279 11.3529 2.48185 11.1414 2.06472C10.9553 1.6978 10.6583 1.39949 10.293 1.21254C9.8778 1 9.33422 1 8.24706 1H4.10588C3.01872 1 2.47514 1 2.0599 1.21254C1.69464 1.39949 1.39768 1.6978 1.21158 2.06472C1 2.48185 1 3.0279 1 4.12V10.88C1 11.9721 1 12.5182 1.21158 12.9353C1.39768 13.3022 1.69464 13.6005 2.0599 13.7875C2.47514 14 3.01872 14 4.10588 14H6.17647M8.76471 10.1L12 13.35M12 10.1L8.76471 13.35"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContractIcon;
