const NotificationsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.624 21.9A4.303 4.303 0 0 0 10.5 23c1.103 0 2.11-.415 2.876-1.1m3.647-14.3a6.64 6.64 0 0 0-1.91-4.667A6.484 6.484 0 0 0 10.5 1c-1.73 0-3.389.695-4.612 1.933A6.64 6.64 0 0 0 3.978 7.6c0 3.4-.848 5.727-1.795 7.266C1.385 16.165.986 16.814 1 16.995c.017.2.059.277.218.397.145.108.795.108 2.095.108h14.374c1.3 0 1.95 0 2.095-.108.16-.12.201-.197.218-.397.014-.181-.385-.83-1.183-2.129-.947-1.54-1.794-3.867-1.794-7.266Z"
      />
    </svg>
  );
};

export default NotificationsIcon;
