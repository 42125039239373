import { OrganizationFilterSelectableEntities } from "@/lib/interfaces/organisation";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUser } from "../../hooks/use-user";
import { UserRole } from "../../lib/interfaces/user";
import FilterIcon from "../../ui/components/icons/filter-icon";
import { Button } from "../../ui/components/ui/button";
import DataFetchParent from "../../ui/components/ui/data-fetch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/components/ui/select";
import AddOrganisationForm from "./root/add-organisation";
import OrganisationFetcher from "./root/organisation-fetcher";

const Organisations = () => {
  const [showInvitations, setShowInvitations] = useState(false);
  const [invitationCount, setInvitationCount] = useState(0);
  const [filterSelectableEntities, setFilterSelectableEntities] = useState<OrganizationFilterSelectableEntities>();
  const [initLoading, setInitLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useUser();

  useEffect(() => {
    // rerender
  }, [reload]);

  useEffect(() => {
    if (filterSelectableEntities) {
      setInitLoading(false);
    }
  }, [filterSelectableEntities]);

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex justify-between">
        {!showInvitations ? (
          <DataFetchParent
            isLoading={initLoading}
            data={filterSelectableEntities}
            renderElement={(data) => <OrderCustomerFilters setSearchQuery={setSearchQuery} selectableEntities={data} />}
          />
        ) : (
          <div></div>
        )}
        {user?.userRole !== UserRole.User && (
          <div className="flex gap-3">
            <Button onClick={() => setShowInvitations(!showInvitations)} variant="outline">
              {showInvitations
                ? "Kunden anzeigen"
                : invitationCount + (invitationCount === 1 ? " Einladung" : " Einladungen") + " anzeigen"}
            </Button>
            <AddOrganisationForm setReload={setReload} />
          </div>
        )}
      </div>
      <OrganisationFetcher
        showInvitations={showInvitations}
        setInvitationCount={setInvitationCount}
        setFilterSelectableEntities={setFilterSelectableEntities}
        searchQuery={searchQuery}
      />
    </div>
  );
};

const OrderCustomerFilters = ({
  setSearchQuery,
  selectableEntities,
}: {
  setSearchQuery: Dispatch<SetStateAction<string>>;
  selectableEntities: OrganizationFilterSelectableEntities;
}) => {
  const [activeFilter, setActiveFilter] = useState(false);
  const [inactiveFilter, setInactiveFilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [firstRender, setFirstRender] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    let filter = "";
    if (activeFilter) {
      filter = "status=ACTIVE";
    } else if (inactiveFilter) {
      filter = "status=INACTIVE";
    } else {
      filter = "status=ALL";
    }

    if (selectedUser) {
      filter += `&assignedInternalUserId=${selectedUser}`;
    }

    setSearchQuery(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, activeFilter, inactiveFilter, setSearchQuery]);

  return (
    <div className="flex items-center gap-4">
      <div className="flex gap-3">
        <Button
          variant={activeFilter ? "default" : "outline"}
          onClick={() => {
            setActiveFilter((prev) => !prev);
            setInactiveFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={activeFilter} />
          Aktive Kunden
        </Button>
        <Button
          variant={inactiveFilter ? "default" : "outline"}
          onClick={() => {
            setInactiveFilter((prev) => !prev);
            setActiveFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={inactiveFilter} />
          Inaktive Kunden
        </Button>
      </div>
      {user?.userRole !== UserRole.User && (
        <div className="relative">
          <Select
            onValueChange={(value) => {
              setSelectedUser(value);
            }}
            defaultValue={selectableEntities.userFilterUsed}
          >
            <SelectTrigger className="flex bg-white focus:outline-none focus:ring-0 focus:ring-offset-0 items-center rounded-2xl pl-4 pr-4 py-2 border border-[#D0D5DD] text-[#888] text-sm gap-3">
              {/* <ChevronDown size="18" /> */}
              <SelectValue placeholder="Mitarbeiter" />
            </SelectTrigger>
            <SelectContent className="cursor-pointer">
              <SelectItem value="ALL">Alle Mitarbeiter</SelectItem>
              {selectableEntities?.internalUsers.map((user) => (
                <SelectItem key={user._id} value={user._id}>
                  {user.value}
                </SelectItem>
              ))}
              <SelectItem value="NONE">Kein Mitarbeiter</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  );
};

export default Organisations;
