import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useApiRequest from "../../lib/hooks/useRequest";
import { Button } from "../../ui/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../ui/components/ui/dialog";

export type Job = {
  _id: string;
  title: string;
};

const ConfirmationDialog = ({
  type,
  job,
  setReload,
  setJob,
}: {
  type: "customer" | "internal";
  job?: Job;
  setJob: Dispatch<SetStateAction<Job | undefined>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { apiRequest } = useApiRequest();

  useEffect(() => {
    if (job) {
      setIsOpen(true);
    }
  }, [job]);

  const deleteJob = async () => {
    if (!job) return;
    await apiRequest(`jobs/${type}/${job._id}`, "DELETE", {
      toast: {
        toastText: "Auftrag wurde gelöscht.",
      },
    });
    setReload((prev) => !prev);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open) {
          setJob(undefined);
        }
      }}
    >
      <DialogContent className="z-50">
        <DialogHeader>
          <DialogTitle>Job löschen?</DialogTitle>
        </DialogHeader>
        <div>
          <p>
            Möchten Sie den Job <span className="font-bold">{job?.title}</span> wirklich löschen?
          </p>
        </div>
        <DialogFooter>
          <div className={`flex gap-3 w-full`}>
            <Button
              onClick={() => {
                setIsOpen(false);
                setJob(undefined);
              }}
              className="grow w-1/2"
            >
              Abbrechen
            </Button>
            <Button variant="destructive" onClick={() => deleteJob()} className="grow w-1/2">
              Löschen
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
