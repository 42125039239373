import { UserProfileResponseDto } from "@/lib/interfaces/user";
import { CloudUpload, MailIcon, UserIcon } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import useApiRequest from "../../lib/hooks/useRequest";
import NotificationsIcon from "../../ui/components/icons/notifications-icon";
import { Button } from "../../ui/components/ui/button";
import DataFetchParent from "../../ui/components/ui/data-fetch";
import { Input } from "../../ui/components/ui/input";
import { Switch } from "../../ui/components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/components/ui/tabs";

const Settings = () => {
  const { apiRequest } = useApiRequest();
  const [userInfos, setUserInfos] = useState<UserProfileResponseDto>();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getUserSettings = useCallback(async () => {
    setLoading(true);
    const response = await apiRequest<UserProfileResponseDto>("user-profile", "GET");
    if (response) {
      setUserInfos(response.data);
    }
    setLoading(false);
  }, [apiRequest]);

  useEffect(() => {
    getUserSettings();
  }, [getUserSettings, reload]);

  return (
    <div className="flex flex-col gap-9 h-full">
      <div>
        <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">Einstellungen</h1>
        <p className="text-[16px] text-[#888]">Verwalte Dein Konto</p>
      </div>

      <Tabs defaultValue="account" className="w-full">
        <TabsList className="gap-4">
          <TabsTrigger value="account">
            <UserIcon />
            Meine Details
          </TabsTrigger>
          <TabsTrigger value="notifications">
            <NotificationsIcon />
            Benachrichtigungen
          </TabsTrigger>
          {/* <TabsTrigger value="invoice">
            <PackagePlus />
            Abrechnung
          </TabsTrigger> */}
        </TabsList>
        <DataFetchParent
          data={userInfos}
          isLoading={loading}
          renderElement={(data: UserProfileResponseDto) => (
            <>
              <TabsContent value="account">
                <DetailsController userInfo={data} setReload={setReload} />
              </TabsContent>
              <TabsContent value="notifications">
                <NotifactionsController userInfo={data} setReload={setReload} />
              </TabsContent>
              {/* <TabsContent value="invoice">
                <div className="flex flex-col py-6 px-12 bg-white rounded-xl gap-3">
                  <p className="text-2xl text-foreground font-bold">Abrechnung</p>

                  <div className="flex gap-2 flex-col items-start">
                    <p className="font-bold leading-9">Zahlungsmethode</p>

                    <p className="text-sm leading-7 text-[#888888]">Ändere Deine Zahlungsmethode und Rechnungsdaten</p>

                    <div className="flex rounded-xl border border-gray-200 p-4 gap-4">
                      <div>
                        <VisaIcon />
                      </div>
                      <div className="text-xs">
                        Visa ending in 1234
                        <br />
                        Expiry 06/2024
                        <br />
                        <span className="flex items-center gap-1 mt-1">
                          <MailIcon size="15" />
                          billing@untitledui.com
                        </span>
                      </div>
                      <div>
                        <Button variant="outline" className="text-sm font-semibold">
                          bearbeiten
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col py-6 px-12 bg-white rounded-xl gap-5 mt-[34px]">
                  <div className="flex justify-between items-center">
                    <p className="text-2xl text-foreground font-bold">Rechnungen</p>
                    <Button
                      variant="outline"
                      className="flex items-center gap-4 text-[#888] border-2 border-[#D0D5DD] rounded-xl text-[17px]"
                    >
                      <DownloadIcon />
                      <span>Alle downloaden</span>
                    </Button>
                  </div>

                  <div className="flex gap-10 w-full flex-col">
                    <DataTable data={invoices} />
                  </div>
                </div>
              </TabsContent> */}
            </>
          )}
        />
      </Tabs>
    </div>
  );
};

const DetailsController = ({
  userInfo,
  setReload,
}: {
  userInfo: UserProfileResponseDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest, apiFileRequest } = useApiRequest();
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const resetPassword = async () => {
    await apiRequest("user-profile/reset-password", "POST", {
      toast: {
        toastText: "Email zum Passwort zurücksetzen wurde gesendet",
      },
    });
  };

  const uploadFiles = useCallback(async () => {
    await apiFileRequest<string>(`user-profile/image`, "POST", file);
    setReload((prev) => !prev);
    window.location.reload();
  }, [apiFileRequest, file, setReload]);

  const handleUpload = (file: File) => {
    setFile(file);
  };

  const handleUploadClick = () => {
    const uploadBtn = document.querySelector(`#upload-chat`) as HTMLButtonElement | null;
    if (uploadBtn) {
      uploadBtn.click();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileDrop(event.dataTransfer.files);
    }
  };

  const handleFileDrop = (files: FileList | null) => {
    if (files?.length === 0 || !files) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const filteredFiles = Array.from(files).filter((file) => allowedTypes.includes(file.type));
    handleUpload(filteredFiles[0]);
  };

  const deleteProfilePicture = async () => {
    await apiRequest("user-profile/image", "DELETE", {
      toast: {
        toastText: "Profilbild wurde gelöscht",
      },
    });
    setReload((prev) => !prev);
    window.location.reload();
  };

  useEffect(() => {
    if (file) {
      uploadFiles();
    }
  }, [file, uploadFiles]);

  return (
    <div className="flex flex-col py-6 px-12 bg-white rounded-xl gap-5">
      <p className="text-2xl text-foreground font-bold">Profil</p>

      <div className="flex gap-10 w-full flex-col md:flex-row">
        <div className="flex w-full md:w-1/2 flex-col gap-2">
          <p className="font-bold leading-9">Name des Benutzers:*</p>
          <div className="flex gap-3 w-full">
            <div className="rounded-xl px-3 py-2 border border-[#D0D5DD] text-sm w-1/2">
              <input
                placeholder="Vorname"
                className="border-none outline-none w-full bg-white"
                value={userInfo.firstName}
                disabled
              />
            </div>
            <div className="rounded-xl px-3 py-2 border border-[#D0D5DD] text-sm w-1/2">
              <input
                placeholder="Nachname"
                className="border-none outline-none w-full bg-white"
                value={userInfo.lastName}
                disabled
              />
            </div>
          </div>
          <p className="font-bold leading-9 mt-3">E-Mail:*</p>
          <div className="rounded-xl px-3 py-2 border border-[#D0D5DD] text-sm">
            <input
              placeholder="E-Mail"
              className="border-none outline-none w-full bg-white"
              value={userInfo.mail}
              disabled
            />
          </div>
        </div>
        <div className="flex w-full md:w-1/2 flex-col gap-2">
          {userInfo.profilePictureBase64 ? (
            <>
              <img
                src={"data:image/*;base64, " + userInfo.profilePictureBase64}
                alt="profile"
                className="rounded-xl w-32 h-32"
              />
              <Button variant="outline" onClick={deleteProfilePicture}>
                Profilbild löschen
              </Button>
            </>
          ) : (
            <>
              <p className="font-bold leading-9">Profilbild hochladen:</p>
              <div
                className={`flex flex-col rounded-xl px-6 py-4 border border-[#D0D5DD] text-sm justify-center items-center ${
                  isDragging ? "border-[#C0DE60] border-dotted" : ""
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleUploadClick}
              >
                <Button variant="outline">
                  <CloudUpload />
                </Button>
                <p className="text-sm mt-3">
                  <span className="text-[#C0DE60] font-medium">Zum Upload klicken</span> oder hier reinziehen
                </p>
                <p className="text-xs">PDF, SVG, PNG, JPG or GIF (max. 5 MB)</p>
                <Input
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  id="upload-chat"
                  onChange={(e) => {
                    e.preventDefault();
                    if (e.target?.files && e.target?.files.length > 0) {
                      handleUpload(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-5">
        <Button className="flex items-center gap-4" onClick={resetPassword}>
          <MailIcon />
          Passwort zurücksetzen
        </Button>
      </div>
    </div>
  );
};

const NotifactionsController = ({
  userInfo,
  setReload,
}: {
  userInfo: UserProfileResponseDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest } = useApiRequest();
  const [appNotificationChatEnabled, setAppNotificationChatEnabled] = useState(userInfo.appNotificationChatEnabled);
  const [appNotificationStatusEnabled, setAppNotificationStatusEnabled] = useState(
    userInfo.appNotificationStatusEnabled
  );
  const [mailNotificationChatEnabled, setMailNotificationChatEnabled] = useState(userInfo.mailNotificationChatEnabled);
  const [mailNotificationStatusEnabled, setMailNotificationStatusEnabled] = useState(
    userInfo.mailNotificationStatusEnabled
  );

  const updateUser = useCallback(async () => {
    await apiRequest("user-profile", "PATCH", {
      body: {
        appNotificationChatEnabled,
        appNotificationStatusEnabled,
        mailNotificationChatEnabled,
        mailNotificationStatusEnabled,
      },
      toast: {
        toastText: "Benachrichtigungseinstellungen wurden aktualisiert",
      },
    });
    setReload((prev) => !prev);
  }, [
    apiRequest,
    appNotificationChatEnabled,
    appNotificationStatusEnabled,
    mailNotificationChatEnabled,
    mailNotificationStatusEnabled,
    setReload,
  ]);

  useEffect(() => {
    if (
      userInfo.appNotificationChatEnabled !== appNotificationChatEnabled ||
      userInfo.appNotificationStatusEnabled !== appNotificationStatusEnabled ||
      userInfo.mailNotificationChatEnabled !== mailNotificationChatEnabled ||
      userInfo.mailNotificationStatusEnabled !== mailNotificationStatusEnabled
    ) {
      updateUser();
    }
  }, [
    appNotificationChatEnabled,
    appNotificationStatusEnabled,
    mailNotificationChatEnabled,
    mailNotificationStatusEnabled,
    updateUser,
    userInfo.appNotificationChatEnabled,
    userInfo.appNotificationStatusEnabled,
    userInfo.mailNotificationChatEnabled,
    userInfo.mailNotificationStatusEnabled,
  ]);

  return (
    <div className="flex flex-col py-6 px-12 bg-white rounded-xl gap-5">
      <p className="text-2xl text-foreground font-bold">Benachrichtigungen</p>

      <div className="flex gap-10 w-full flex-col">
        <div className="flex flex-col gap-2">
          <p className="font-bold leading-9">Auftragsstatus</p>
          <div className="flex gap-3 w-full justify-between">
            <p className="text-sm leading-7 text-[#888888]">
              Nutze die Auftragsbeschreibung, um Deinen Auftrag so detailliert wie möglich zu formulieren. Wichtige
              Elemente hierbei sind die benötigten Formate,
            </p>
            <div className="flex flex-col gap-2 min-w-32">
              <div className="flex justify-between items-center">
                <span className="text-[#888] font-bold">App</span>
                <Switch
                  checked={appNotificationStatusEnabled}
                  onCheckedChange={(e) => setAppNotificationStatusEnabled(e)}
                />
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#888] font-bold">E-Mail</span>
                <Switch
                  checked={mailNotificationStatusEnabled}
                  onCheckedChange={(e) => setMailNotificationStatusEnabled(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-[2px] bg-[#E7E7E7]"></div>
        <div className="flex flex-col gap-2">
          <p className="font-bold leading-9">Neue Nachricht</p>
          <div className="flex gap-3 w-full justify-between">
            <p className="text-sm leading-7 text-[#888888]">
              Nutze die Auftragsbeschreibung, um Deinen Auftrag so detailliert wie möglich zu formulieren. Wichtige
              Elemente hierbei sind die benötigten Formate,
            </p>

            <div className="flex flex-col gap-2 min-w-32">
              <div className="flex justify-between items-center">
                <span className="text-[#888] font-bold">App</span>
                <Switch
                  checked={appNotificationChatEnabled}
                  onCheckedChange={(e) => setAppNotificationChatEnabled(e)}
                />
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#888] font-bold">E-Mail</span>
                <Switch
                  checked={mailNotificationChatEnabled}
                  onCheckedChange={(e) => setMailNotificationChatEnabled(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
