import { NotificationListResponseDto } from "@/lib/interfaces/notifications";
import { Paginated } from "@/lib/interfaces/utils";
import { Ellipsis, MessageSquareDot } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useApiRequest from "../../../lib/hooks/useRequest";
import { formatTimeSpent } from "../../../lib/hooks/utils";
import NotificationsIcon from "../icons/notifications-icon";
import { Button } from "./button";
import DataFetchParent from "./data-fetch";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

const MessagesPopup = () => {
  const { apiRequest } = useApiRequest();
  const location = useLocation();
  const [notifications, setNotifications] = useState<NotificationListResponseDto[]>();
  const [statusFilter, setStatusFilter] = useState<"ALL" | "UNREAD">("UNREAD");
  const [loading, setLoading] = useState(false);

  const getNotifications = useCallback(async () => {
    setLoading(true);
    const res = await apiRequest<Paginated<NotificationListResponseDto>>(
      `notifications?statusFilter=${statusFilter}`,
      "GET"
    );
    if (res) {
      setNotifications(res.data?.docs);
    }
    setLoading(false);
  }, [apiRequest, statusFilter]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications, location]);

  const markAllAsRead = async () => {
    await apiRequest("notifications", "PATCH");
    getNotifications();
  };

  return (
    <Popover>
      <PopoverTrigger
        title="Benachrichtigungen"
        className={`rounded-full relative w-[60px] h-[60px] flex justify-center items-center bg-transparent hover:bg-white hover:bg-opacity-10`}
      >
        {notifications && !loading && statusFilter === "UNREAD" && notifications?.length > 0 && (
          <div className={`absolute top-3 right-3 w-[11px] h-[11px] rounded-full bg-red-700`}></div>
        )}
        <NotificationsIcon />
      </PopoverTrigger>
      <PopoverContent className="absolute -top-32 left-full z-30 ml-16 max-w-[620px] min-w-[600px] w-full min-h-52">
        <div className="h-full w-full py-4 flex flex-col gap-3 max-h-[590px] overflow-hidden">
          <div className="flex items-center justify-between mx-4">
            <p className="font-bold text-[20px]">Benachrichtigungen</p>
            <div className="flex items-center gap-2">
              <Button
                variant={statusFilter === "ALL" ? "active" : "outline"}
                size="active"
                className="text-xs leading-7"
                onClick={() => {
                  setStatusFilter("ALL");
                }}
              >
                Alle
              </Button>
              <Button
                variant={statusFilter === "ALL" ? "outline" : "active"}
                size="active"
                className="text-xs leading-7"
                onClick={() => {
                  setStatusFilter("UNREAD");
                }}
              >
                Ungelessen
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Ellipsis />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="absolute -right-2 top-2">
                  <DropdownMenuItem
                    className="text-xs flex items-center gap-2 font-semibold cursor-pointer"
                    onClick={markAllAsRead}
                  >
                    <MessageSquareDot size="15" />
                    Alle als “Gelesen” markieren
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <DataFetchParent
            data={notifications}
            isLoading={loading}
            renderElement={(data: NotificationListResponseDto[]) => {
              return (
                <div className="overflow-y-scroll ml-2 pr-1 custom-scrollbar">
                  {data.map((item, index) => {
                    return (
                      <div key={item._id}>
                        <MessageItem {...item} />
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
          {!loading && notifications?.length === 0 && (
            <div className="flex items-center justify-center h-full">
              <p className="text-[#A0A0A0]">Keine Benachrichtigungen</p>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const MessageItem = ({
  _id,
  belongsToJobId,
  belongsToJobIncrementId,
  belongsToJobName,
  message,
  read,
  triggerUserProfilePictureBase64,
}: NotificationListResponseDto) => {
  const date = new Date().toDateString();
  return (
    <Link to={`/order/${belongsToJobId}`}>
      <div
        className={`flex group w-full rounded-[36px] px-2 justify-between cursor-pointer items-center text-xs my-2 py-2 hover:bg-gray-100 ${
          read ? "opacity-50" : ""
        }`}
      >
        <div className="relative">
          <img
            src={
              triggerUserProfilePictureBase64
                ? "data:image/*;base64, " + triggerUserProfilePictureBase64
                : "/user-default.svg"
            }
            alt={belongsToJobName}
            width={54}
            height={54}
            className="rounded-full border border-[#D3DCCF] h-14 w-14"
          />
          <div
            className={`flex absolute text-white items-center justify-center rounded-full -bottom-1 w-[27px] h-[27px] -right-1 border-[3px] border-white bg-gradient-to-b`}
          ></div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <span className="rounded-[47px] flex border border-[#EAECF0] leading-7 justify-center w-[60px] font-bold">
              {belongsToJobIncrementId}
            </span>
            <p className="font-bold">{belongsToJobName}</p>
          </div>
          <div className="text-[#D0D5DD] group-hover:text-gray-500">{message}</div>
        </div>
        <div className="flex flex-col flex-end text-[10px] text-[#D0D5DD] mt-auto">{formatTimeSpent(date)}</div>
        <div>
          <div
            className={`w-[15px] h-[15px] rounded-full ${read ? "bg-[#D0D5DD]" : "bg-red-300"}  group-hover:bg-red-500`}
          ></div>
        </div>
      </div>
    </Link>
  );
};

export default MessagesPopup;
