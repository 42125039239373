import { zodResolver } from "@hookform/resolvers/zod";
import { Mail, UserIcon } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../../lib/hooks/useRequest";
import AddIcon from "../../../ui/components/icons/add-icon";
import { Button } from "../../../ui/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";

const AddOrganisationForm = ({ setReload }: { setReload: Dispatch<SetStateAction<boolean>> }) => {
  const { apiRequest } = useApiRequest();
  const [open, setOpen] = useState(false);

  const addCustomerSchema = z.object({
    firstname: z
      .string()
      .max(45, {
        message: "Der Name darf höchstens 45 Zeichen lang sein",
      })
      .min(1, {
        message: "Vorname ist erforderlich",
      }),
    lastname: z.string().min(1, {
      message: "Nachname ist erforderlich",
    }),
    email: z.string().email("Bitte geben Sie Ihre Email-Adresse ein"),
    organisationName: z.string().min(1, {
      message: "Unternehmensname ist erforderlich",
    }),
    debitReference: z.string().min(1, {
      message: "Debitreferenz ist erforderlich",
    }),
    signatureDate: z.string().min(1, {
      message: "Datum der Unterschrift ist erforderlich",
    }),
    allowedJobsCount: z.number().min(1, {
      message: "Erlaubte Jobsanzahl ist erforderlich",
    }),
    newCustomerDate: z.string().min(1, {
      message: "Beginn der Zusammenarbeit ist erforderlich",
    }),
  });

  type AddCustomerSchema = z.infer<typeof addCustomerSchema>;

  const form = useForm<AddCustomerSchema>({
    resolver: zodResolver(addCustomerSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      organisationName: "",
      debitReference: "",
      signatureDate: "",
      allowedJobsCount: 0,
      newCustomerDate: "",
    },
  });

  const onSubmit = async (data: AddCustomerSchema) => {
    const req = {
      firstName: data.firstname,
      lastName: data.lastname,
      mail: data.email,
      name: data.organisationName,
      debitReference: data.debitReference,
      signatureDate: data.signatureDate,
      allowedJobsCount: data.allowedJobsCount,
      newCustomerDate: data.newCustomerDate,
    };
    await apiRequest("organizations/internal/invitations", "POST", {
      body: req,
      toast: { toastText: "Organisation wurde erfolgreich eingeladen" },
    });
    setOpen(false);
    setReload((prev) => !prev);
  };

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <Button className="gap-4" onClick={() => setOpen(true)}>
        <AddIcon />
        <span>Neuer Kunde</span>
      </Button>
      <DialogContent className="sm:max-w-md min-w-[560px] py-4 px-6 gap-5">
        <DialogHeader className="mb-7">
          <DialogTitle className="flex items-center gap-3">
            <UserIcon />
            <p className="text-[25px] font-bold">Neuen Kunden hinzufügen</p>
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-7 h-full">
            <div className="flex flex-col gap-3">
              <FormLabel className="text-[16px] font-bold text-[#12282A]">Name des Benutzers:*</FormLabel>
              <div className="flex gap-3">
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="firstname"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="Vorname" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="lastname"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="Nachname" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-3">
                <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Unternehmensname:*</FormLabel>
                <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">E-Mail:*</FormLabel>
              </div>
              <div className="flex gap-3">
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="organisationName"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="Unternehmensname" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="E-Mail" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-3">
                <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Madatsreferenz:*</FormLabel>
                <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Datum der Unterschrift:*</FormLabel>
              </div>
              <div className="flex gap-3">
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="debitReference"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="Debitreferenz" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="signatureDate"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="Signaturdatum" type="date" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-3">
                <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Erlaubte Jobsanzahl:*</FormLabel>
                <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">
                  Beginn der Zusammenarbeit:*
                </FormLabel>
              </div>
              <div className="flex gap-3">
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="allowedJobsCount"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Erlaubte Jobsanzahl"
                            type="number"
                            min={0}
                            onChange={(e) => {
                              field.onChange(parseInt(e.target.value));
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-1/2">
                  <FormField
                    control={form.control}
                    name="newCustomerDate"
                    render={({ field }) => (
                      <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                        <FormControl>
                          <Input {...field} placeholder="Beginn der Zusammenarbeit" type="date" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <DialogFooter className="sm:justify-start mt-4">
              {/* <DialogClose asChild>
                <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                  <Mail /> <span>Einladung senden</span>
                </Button>
              </DialogClose> */}
              <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                <Mail /> <span>Einladung senden</span>
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddOrganisationForm;
