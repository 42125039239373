import { ChatDto, ChatResponseDto } from "@/lib/interfaces/messages";
import { zodResolver } from "@hookform/resolvers/zod";
import { Download, FileType, LoaderCircle, Paperclip, X } from "lucide-react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { io } from "socket.io-client";
import { z } from "zod";
import { useUser } from "../../hooks/use-user";
import useApiRequest from "../../lib/hooks/useRequest";
import { downloadURI, formatDateTime } from "../../lib/hooks/utils";
import { UserType } from "../../lib/interfaces/user";
import { Button } from "../../ui/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";
import Preloader from "../../ui/components/ui/preloader";
import { Textarea } from "../../ui/components/ui/textarea";

const Chat = ({ id, type }: { id: string; type: "internal" | "customer" }) => {
  const { apiRequest, apiFileRequest } = useApiRequest();
  const { user } = useUser();
  const userType = user?.userType === UserType.Customer ? "customer-user" : "internal-user";
  const [messages, setMessages] = useState<ChatDto[]>([]);
  const [chatToken, setChatToken] = useState<string | null>(null);
  const chat = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);

  const formSchema = z
    .object({
      message: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      const message = data.message?.trim();

      if (!file && (!message || message === "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["message"],
          message: "Beschreibung ist erforderlich",
        });
      }
    });

  type FormSchema = z.infer<typeof formSchema>;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = async (data: FormSchema) => {
    setSubmitting(true);
    if (file) {
      await apiFileRequest(`jobs/${type}/${id}/messages/file`, "POST", file, {
        formdataName: "file",
      });
      setFile(null);
    } else {
      const req = {
        message: data.message,
      };
      await apiRequest(`jobs/${type}/${id}/messages`, "POST", {
        body: req,
      });
    }
    setSubmitting(false);
    getMessages();
  };

  const getMessages = useCallback(async () => {
    setIsLoading(true);
    const res = await apiRequest<ChatResponseDto>(`jobs/${type}/${id}/messages${page ? `?page=${page}` : ""}`, "GET");
    if (res.data) {
      const newPosts = res.data.chat.docs;

      setMessages((oldValues) => {
        const combined = [...oldValues, ...newPosts];
        const final: ChatDto[] = [];
        combined.map((value) => {
          const index = final.findIndex((item) => item._id === value._id);
          if (index === -1) {
            final.push(value);
          }
          return null;
        });
        return final.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      });

      setChatToken(res.data.chatKey);
      setHasNextPage(res.data.chat.hasNextPage);
      form.reset();
    }
    setIsLoading(false);
  }, [apiRequest, form, id, page, type]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const scrollToBottom = () => {
    if (chat.current) {
      chat.current.scrollTop = chat.current.scrollHeight;
    }
  };

  useEffect(() => {
    const implemented = true;

    if (chatToken && implemented) {
      const socketConnection = io(process.env.REACT_APP_API, {
        query: { jobId: id },
        extraHeaders: {
          authentication: chatToken,
        },
      });

      socketConnection.on("newMessage", (message: ChatDto) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      return () => {
        socketConnection.disconnect();
      };
    }
  }, [chatToken, id]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const messageIsOwn = (message: ChatDto) => {
    return message.belongsToUserType === userType;
  };

  const lastPostElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (hasNextPage) {
            setPage((prevPage) => prevPage + 1);
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasNextPage, isLoading]
  );

  return (
    <div className="flex flex-col w-1/2 rounded-[14px]">
      <div className="px-6 py-4 bg-[#12282A] rounded-t-[14px]">
        <p className="text-center text-white text-2xl font-bold">Chat</p>
      </div>
      <div className="p-5 bg-white rounded-b-[14px] flex flex-col justify-between flex-grow overflow-hidden gap-2">
        <div className="flex flex-col gap-6 overflow-auto custom-scrollbar pr-3" ref={chat}>
          {isLoading && <Preloader />}
          {messages.map((message, index) => {
            const isOwn = messageIsOwn(message);
            let withRef: {
              ref?: any;
            } = {};

            if (messages.length === index + 1) {
              withRef = {
                ref: lastPostElementRef,
              };
            }

            return isOwn ? (
              <OwnMessage message={message} key={message._id} type={type} id={id} {...withRef} />
            ) : (
              <OtherMessage message={message} key={message._id} type={type} id={id} {...withRef} />
            );
          })}
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5">
            <div className="flex flex-col gap-2 rounded-lg px-3 py-2 border border-[#D0D5DD]">
              <div className="flex justify-end items-center gap-4">
                {file ? (
                  <>
                    <div className="border p-2 rounded-xl flex justify-start gap-2 self-start">
                      <span className="font-medium">{file.name}</span>
                      <Button variant="link" onClick={() => setFile(null)} className="p-0 self-center m-0 h-fit">
                        <X size={20} />
                      </Button>
                    </div>
                    <div className="grow"></div>
                  </>
                ) : (
                  <FormField
                    control={form.control}
                    name="message"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <Textarea
                            {...field}
                            placeholder="Schreibe eine Nachricht"
                            className="w-full custom-scrollbar resize-none min-h-20"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <div className="flex flex-col gap-2">
                  <Button variant="default" type="submit" disabled={submitting}>
                    {submitting && <LoaderCircle className="animate-spin" />}
                    Senden
                  </Button>
                  <ChatFileUpload setFile={setFile} />
                </div>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

const ChatFileUpload = ({ setFile }: { setFile: (file: File) => void }) => {
  const handleUpload = (file: File) => {
    setFile(file);
  };

  const handleUploadClick = () => {
    const uploadBtn = document.querySelector(`#upload-chat`) as HTMLButtonElement | null;
    if (uploadBtn) {
      uploadBtn.click();
    }
  };

  return (
    <div className="self-end w-fit">
      <Paperclip onClick={handleUploadClick} className="cursor-pointer" />
      <Input
        type="file"
        className="hidden"
        id="upload-chat"
        onChange={(e) => {
          e.preventDefault();
          if (e.target?.files && e.target?.files.length > 0) {
            handleUpload(e.target.files[0]);
          }
        }}
      />
    </div>
  );
};

const OwnMessage = forwardRef(
  (
    { message, type, id }: { message: ChatDto; type: "internal" | "customer"; id: string },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { apiRequest } = useApiRequest();

    const downloadFile = async () => {
      const res = await apiRequest<{
        fileUrl: string;
      }>(`jobs/${type}/${id}/messages/${message._id}`, "GET", {
        toast: {
          toastText: "Datei wird heruntergeladen",
        },
      });
      if (res.data) {
        downloadURI(res.data.fileUrl, message.file.name);
      }
    };

    return (
      <div key={message._id} className="flex gap-3 w-full justify-end" ref={ref}>
        <div className="flex flex-col gap-2 max-w-[calc(100%-60px)]">
          <div className="flex justify-between items-center gap-4">
            <span className="font-medium text-sm truncate">{message.isOwn ? "Du" : message.belongsToUserFullName}</span>
            <span className="text-xs whitespace-nowrap">{formatDateTime(message.createdAt)}</span>
          </div>

          {message.file && (
            <div className="flex items-center gap-3 rounded-lg px-3 py-2 border border-[#D0D5DD] text-white bg-primary">
              <div className="flex gap-2 items-center min-w-0">
                <FileType className="flex-shrink-0" />
                <span className="font-medium truncate">{message.file.name}</span>
                <Button variant="link" className="text-white flex-shrink-0 ml-2" onClick={() => downloadFile()}>
                  <Download size={20} />
                </Button>
              </div>
            </div>
          )}

          {message.message && (
            <div className="bg-primary text-white rounded-lg self-end max-w-full">
              <div className="py-2 px-3 text-sm">{parseText(message.message)}</div>
            </div>
          )}
        </div>

        <img
          src={
            message.belongsToUserProfilePictureBase64
              ? "data:image/*;base64, " + message.belongsToUserProfilePictureBase64
              : "/user-default.svg"
          }
          alt={message.isOwn ? "Du" : message.belongsToUserFullName}
          width={40}
          height={40}
          className="rounded-full h-12 w-12 flex-shrink-0"
        />
      </div>
    );
  }
);

const OtherMessage = forwardRef(
  (
    { message, type, id }: { message: ChatDto; type: "internal" | "customer"; id: string },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { apiRequest } = useApiRequest();

    const downloadFile = async () => {
      const res = await apiRequest<{
        fileUrl: string;
      }>(`jobs/${type}/${id}/messages/${message._id}`, "GET", {
        toast: {
          toastText: "Datei wird heruntergeladen",
        },
      });
      if (res.data) {
        downloadURI(res.data.fileUrl, message.file.name);
      }
    };

    return (
      <div key={message._id} className="flex gap-3" ref={ref}>
        <img
          src={
            message.belongsToUserProfilePictureBase64
              ? "data:image/*;base64, " + message.belongsToUserProfilePictureBase64
              : "/user-default.svg"
          }
          alt="Max Mitarbeiter"
          width={40}
          height={40}
          className="rounded-full h-12 w-12"
        />
        <div className="flex flex-col gap-2 items-start">
          <div className="flex justify-between items-center gap-4">
            <span className="font-medium text-sm">{message.belongsToUserFullName}</span>
            <span className="text-xs">{formatDateTime(message.createdAt)}</span>
          </div>
          {message.file && (
            <div className="flex justify-between items-center gap-3 rounded-lg px-3 py-2 border border-[#D0D5DD] text-sm mr-2">
              <div className="flex gap-2 items-center">
                <FileType />
                <div className="flex text-sm justify-between">
                  <p className="font-medium self-center">{message.file.name}</p>
                  <Button variant="link" className="text-[#12282A]" onClick={() => downloadFile()}>
                    <Download size={20} />
                  </Button>
                </div>
              </div>
            </div>
          )}
          {message.message && (
            <div className="border rounded-b-lg rounded-tr-lg">
              <div className="py-2 px-3 text-sm">{parseText(message.message)}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

// <div className="flex gap-3 items-start">
//   <img src="/user-avatar.jpg" alt="Max Mitarbeiter" width={40} height={40} className="rounded-full" />
//   <div className="flex flex-col w-full gap-2">
//     <div className="flex justify-between items-center">
//       <span className="font-medium text-sm">Max Mitarbeiter</span>
//       <span className="text-xs">Donnerstag 11:45</span>
//     </div>
//     <div className="flex justify-between items-center gap-3 rounded-lg px-3 py-2 border border-[#D0D5DD] text-sm mr-2 w-full">
//       <div className="flex gap-2 items-center">
//         <FileType type={FileTypeEnum.PDF} />
//         <div className="flex flex-col text-sm justify-between">
//           <p className="font-medium">Erster_Entwurf.pdf</p>
//           <p className="text-xs">1.2 MB</p>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="flex gap-3 items-start">
//   <img src="/user-avatar.jpg" alt="Max Mitarbeiter" width={40} height={40} className="rounded-full" />
//   <div className="flex flex-col w-full gap-2">
//     <div className="flex justify-between items-center">
//       <span className="font-medium text-sm">Max Mitarbeiter</span>
//       <span className="text-xs">Donnerstag 11:47</span>
//     </div>
//     <p className="py-2 px-2 text-sm">Vielen Dank für den tollen Entwurf!</p>
//   </div>
// </div>
// <div className="ml-auto flex flex-col gap-2">
//   <div className="flex justify-between items-center">
//     <span className="font-medium text-sm">Du</span>
//     <span className="text-xs">Donnerstag 11:50</span>
//   </div>
//   <p className="py-[10px] px-[14px] rounded-l-lg rounded-br-lg bg-[#12282A] text-white">
//     Vielen Dank für den tollen Entwurf!
//   </p>
// </div>

const parseText = (description: string) => {
  const lines = description.split("\n");
  return lines.map((line, index) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const updatedLine = line.replace(
      urlRegex,
      '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-blue-400 underline hover:no-underline break-all">$1</a>'
    );

    return (
      <p
        key={index}
        dangerouslySetInnerHTML={{
          __html: updatedLine.length > 0 ? updatedLine : "<br />",
        }}
        className="whitespace-pre-wrap break-word"
      />
    );
  });
};

export default Chat;
