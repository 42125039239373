const BankIcon = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.55556 5.654V11.8847M6.05556 5.654V11.8847M9.94444 5.654V11.8847M13.4444 5.654V11.8847M1 13.1308L1 13.7539C1 14.1901 1 14.4081 1.08477 14.5748C1.15934 14.7213 1.27833 14.8404 1.42467 14.9151C1.59105 15 1.80885 15 2.24444 15H13.7556C14.1912 15 14.409 15 14.5753 14.9151C14.7217 14.8404 14.8407 14.7213 14.9152 14.5748C15 14.4081 15 14.1901 15 13.7539V13.1308C15 12.6946 15 12.4765 14.9152 12.3099C14.8407 12.1634 14.7217 12.0442 14.5753 11.9696C14.409 11.8847 14.1912 11.8847 13.7556 11.8847H2.24444C1.80885 11.8847 1.59105 11.8847 1.42467 11.9696C1.27833 12.0442 1.15934 12.1634 1.08477 12.3099C1 12.4765 1 12.6946 1 13.1308ZM7.73004 1.04107L1.97449 2.32182C1.62677 2.3992 1.45292 2.43788 1.32314 2.5315C1.20867 2.61408 1.11879 2.72629 1.06312 2.85609C1 3.00326 1 3.1816 1 3.53828L1 4.40787C1 4.84405 1 5.06215 1.08477 5.22875C1.15934 5.3753 1.27833 5.49444 1.42467 5.56911C1.59105 5.654 1.80885 5.654 2.24444 5.654H13.7556C14.1912 5.654 14.409 5.654 14.5753 5.56911C14.7217 5.49444 14.8407 5.3753 14.9152 5.22875C15 5.06215 15 4.84406 15 4.40787V3.53828C15 3.1816 15 3.00326 14.9369 2.85609C14.8812 2.72629 14.7913 2.61408 14.6769 2.53151C14.5471 2.43788 14.3732 2.3992 14.0255 2.32182L8.26996 1.04107C8.16922 1.01866 8.11884 1.00745 8.06794 1.00298C8.02273 0.999008 7.97727 0.999008 7.93206 1.00298C7.88116 1.00745 7.83078 1.01866 7.73004 1.04107Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BankIcon;
