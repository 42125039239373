import {
  OrganizationFilterSelectableEntities,
  OrganizationInvitationListResponseDto,
  OrganizationListEntityResponseDto,
  OrganizationListPaginatedResponseDtoWithInvitationCountAndEntities,
  OrganizationListResponseDto,
} from "@/lib/interfaces/organisation";
import { Paginated, Pagination } from "@/lib/interfaces/utils";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import useApiRequest from "../../../lib/hooks/useRequest";
import DataFetchParent from "../../../ui/components/ui/data-fetch";
import { InvitationDataTable } from "./invitation-data-table";
import { OrganisationDataTable } from "./organisation-data-table";

const OrganisationFetcher = ({
  showInvitations,
  setInvitationCount,
  searchQuery,
  setFilterSelectableEntities,
}: {
  showInvitations: boolean;
  setInvitationCount: Dispatch<SetStateAction<number>>;
  searchQuery: string;
  setFilterSelectableEntities: Dispatch<SetStateAction<OrganizationFilterSelectableEntities | undefined>>;
}) => {
  return (
    <>
      {showInvitations ? (
        <OrganisationInvitedFetch searchQuery={searchQuery} />
      ) : (
        <OrganisationFetch
          setFilterSelectableEntities={setFilterSelectableEntities}
          setInvitationCount={setInvitationCount}
          searchQuery={searchQuery}
        />
      )}
    </>
  );
};

const OrganisationInvitedFetch = ({ searchQuery }: { searchQuery: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [organisations, setOrganisations] = useState<OrganizationInvitationListResponseDto[]>();
  const [reload, setReload] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { apiRequest } = useApiRequest();

  const getOrganisationsPaginated = useCallback(async () => {
    let url = `organizations/internal/invitations?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`;
    if (searchQuery) {
      url += `&${searchQuery}`;
    }
    setIsLoading(true);
    const res = await apiRequest<Paginated<OrganizationInvitationListResponseDto>>(url, "GET");
    setInitLoading(false);
    setIsLoading(false);
    if (res.data) {
      setOrganisations(res.data.docs);
      setPageCount(Math.ceil(res.data.count / pagination.pageSize));
      setPageCount(res.data.pageCount);
    }
  }, [apiRequest, pagination.pageIndex, pagination.pageSize, searchQuery]);

  useEffect(() => {
    getOrganisationsPaginated();
  }, [pagination, getOrganisationsPaginated, reload, searchQuery]);

  return (
    <>
      <DataFetchParent
        isLoading={initLoading}
        data={organisations}
        renderElement={() => (
          <InvitationDataTable
            data={organisations ?? []}
            setReload={setReload}
            setPagination={setPagination}
            pageCount={pageCount}
            pagination={pagination}
            isLoading={isLoading}
          />
        )}
      />
    </>
  );
};

const OrganisationFetch = ({
  setInvitationCount,
  searchQuery,
  setFilterSelectableEntities,
}: {
  setInvitationCount: Dispatch<SetStateAction<number>>;
  searchQuery: string;
  setFilterSelectableEntities: Dispatch<SetStateAction<OrganizationFilterSelectableEntities | undefined>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [organisations, setOrganisations] = useState<OrganizationListResponseDto[]>();
  const [reload, setReload] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [listEntities, setListEntities] = useState<OrganizationListEntityResponseDto>();

  const { apiRequest } = useApiRequest();

  const getCustomersInit = useCallback(async () => {
    setInitLoading(true);
    const res = await apiRequest<OrganizationListPaginatedResponseDtoWithInvitationCountAndEntities>(
      `organizations/internal/initial?limit=${pagination.pageSize}`,
      "GET"
    );

    setInitLoading(false);
    if (res.data) {
      setOrganisations(res.data.organizations.docs);
      setPageCount(Math.ceil(res.data.organizations.count / pagination.pageSize));
      setPageCount(res.data.organizations.pageCount);
      setFilterSelectableEntities(res.data.filterSelectableEntities);
      setInvitationCount(res.data.invitationCount);
      setListEntities(res.data.listEntities);
    }
  }, [apiRequest, pagination.pageSize, setFilterSelectableEntities, setInvitationCount]);

  const getCustomersPaginated = useCallback(async () => {
    let url = `organizations/internal?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`;
    if (searchQuery) {
      url += `&${searchQuery}`;
    }
    setIsLoading(true);
    const res = await apiRequest<Paginated<OrganizationListResponseDto>>(url, "GET");

    setIsLoading(false);
    if (res.data) {
      setOrganisations(res.data.docs);
      setPageCount(Math.ceil(res.data.count / pagination.pageSize));
      setPageCount(res.data.pageCount);
    }
  }, [apiRequest, pagination.pageIndex, pagination.pageSize, searchQuery]);

  useEffect(() => {
    getCustomersInit();
  }, [getCustomersInit, reload]);

  useEffect(() => {
    if (!initLoading) {
      getCustomersPaginated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, getCustomersPaginated, searchQuery]);

  return (
    <>
      <DataFetchParent
        isLoading={initLoading}
        renderElement={(listEntities) => (
          <OrganisationDataTable
            data={organisations ?? []}
            setReload={setReload}
            setPagination={setPagination}
            pageCount={pageCount}
            pagination={pagination}
            isLoading={isLoading}
            listEntities={listEntities}
          />
        )}
        data={listEntities}
      />
    </>
  );
};

export default OrganisationFetcher;
