const FilterIcon = ({ active }: { active?: boolean }) => {
  return (
    <svg width={21} height={13} viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.68848H19.2474" stroke={active ? "white" : "black"} strokeWidth={2} strokeLinecap="round" />
      <path d="M2.99072 6.5H16.9251" stroke={active ? "white" : "black"} strokeWidth={2} strokeLinecap="round" />
      <path d="M5.64453 11.3115H14.9341" stroke={active ? "white" : "black"} strokeWidth={2} strokeLinecap="round" />
    </svg>
  );
};

export default FilterIcon;
