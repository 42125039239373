import { Pagination } from "@/lib/interfaces/utils";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDown,
  ChevronUp,
  ClipboardPenLine,
  Clock3,
  LockKeyhole,
  Pencil,
  RotateCcw,
  Sparkles,
  Trash2,
} from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, formatDateTime, parseStatus } from "../../../lib/hooks/utils";
import {
  JobListInternalListEntities,
  JobListInternalResponseDto,
  JobStatus,
} from "../../../lib/interfaces/internal/jobs";
import NotificationsIcon from "../../../ui/components/icons/notifications-icon";
import { Button } from "../../../ui/components/ui/button";
import Preloader from "../../../ui/components/ui/preloader";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/ui/table";
import ConfirmationDialog, { Job } from "../confirmation-dialog";
import JobContextMenu from "../context-menu";
import { getNoOrderText } from "../page";

interface DataProps {
  data: JobListInternalResponseDto[];
  listEntities: JobListInternalListEntities;
  setReload: Dispatch<SetStateAction<boolean>>;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  pageCount: number;
  pagination: Pagination;
  isLoading: boolean;
  currentQuery: string;
}

// export const DataTable: React.FC<DataProps> = ({ data, globalFilter, setGlobalFilter }) => {
export const InternalDataTable: React.FC<DataProps> = ({
  data,
  setPagination,
  pageCount,
  isLoading,
  pagination,
  setReload,
  listEntities,
  currentQuery,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();
  const [job, setJob] = useState<Job>();

  const columns: ColumnDef<JobListInternalResponseDto>[] = [
    {
      id: "picture",
      header: "",
      cell: ({ row }) => {
        const hasNotification = listEntities.notificationJobIds.includes(row.original._id);

        return (
          <div
            className={`max-w-[54px] min-w-12 relative rounded-full ${
              hasNotification ? "border-2 border-[#CE0909]" : ""
            }`}
          >
            <img
              src={
                row.original.profilePictureBase64
                  ? "data:image/*;base64, " + row.original.profilePictureBase64
                  : "/user-default.svg"
              }
              alt={row.original.title}
              width={54}
              height={54}
              className="rounded-full h-14 w-14"
            />
            {hasNotification ? (
              <div className="absolute top-0 left-0 flex justify-center items-center rounded-full text-white bg-[#12282A] bg-opacity-80 w-full h-full">
                <NotificationsIcon />
                <div className={`absolute top-2 right-2 w-[8px] h-[8px] rounded-full bg-[#CE0909]`}></div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      id: "position",
      header: "",
      cell: ({ row }) => {
        const test = true;
        if (test) {
          return <LockKeyhole />;
        }

        // {row.original.pinned ? (
        //       <LockKeyhole />
        //     ) : (
        //     )}

        return (
          <div className="flex justify-center items-center gap-4">
            <div className="flex items-center gap-1">
              <div className="flex flex-col justify-center">
                <Button
                  className="p-0 h-5"
                  variant="link"
                  title="Move up"
                  onClick={() => {
                    alert("Move me up!");
                  }}
                >
                  <ChevronUp />
                </Button>
                {/* <span>{row.original.position}</span> */}
                <Button
                  className="p-0 h-5"
                  variant="link"
                  title="Move down"
                  onClick={() => {
                    alert("Move me down!");
                  }}
                >
                  <ChevronDown />
                </Button>
              </div>
              {/* <ChevronsUpDown />
                {row.original.position} */}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "type",
      header: ({ column }) => {
        return (
          <div className="flex gap-2 items-center">
            <p
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              Name
              {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
            </p>
          </div>
        );
      },
      cell: ({ row }) => {
        const order = row.original;
        return (
          <div className="flex flex-col text-sm">
            <p className="font-bold">{capitalizeFirstLetter(order.title)}</p>
            <p className="text-[#888] font-medium">Ansprechpartner: {order.belongsToCustomerUserFullName}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Datum
            {/* {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""} */}
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center gap-2 text-sm text-[#D0D5DD]">
            <Clock3 size="18" />
            {formatDateTime(row.original.createdAt)}
          </div>
        );
      },
    },
    {
      accessorKey: "number",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Nummer
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center text-sm rounded-2xl px-3 py-1 font-bold border border-[#D0D5DD] ">
            {row.original.jobIncrementId}
          </div>
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer max-w-[120px]"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Status
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        const status = row.original.status;

        const statusColors = {
          ACTIVE: "bg-green-500 border-green-500 text-green-500",
          NEW: "bg-sky-500 border-sky-500 text-sky-500",
          FEEDBACK: "bg-red-500 border-red-500 text-red-500",
          DONE: "bg-[#12282A] border-[#12282A] text-[#12282A]",
        };
        const statusIcons = {
          ACTIVE: <LockKeyhole size="18" />,
          NEW: <Sparkles size="18" />,
          FEEDBACK: <RotateCcw size="18" />,
          DONE: <ClipboardPenLine size="18" />,
        };

        return (
          <div
            className={`flex justify-center ${statusColors[status]} border bg-opacity-5 items-center gap-2 text-sm font-bold rounded-2xl px-3 py-1 max-w-[120px]`}
          >
            {statusIcons[status]}
            <span>{parseStatus(status)}</span>
          </div>
        );
      },
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-1 max-w-12 mr-2">
            {row.original.isDeleteable && (
              <Button
                variant="link"
                className="h-8 w-8 p-0 text-red-500"
                onClick={(e) => {
                  e.stopPropagation();
                  setJob(row.original);
                }}
              >
                <Trash2 size="18" />
              </Button>
            )}
            <Link to={`../order/${row.original._id}`}>
              <Button variant="link" className="h-8 w-8 p-0">
                <Pencil size="18" />
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  // fetch the data
  // as soon as it is fetched set the data to the table

  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    // globalFilterFn: globalFilterFunction,
    // onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 } },
    state: {
      pagination,
      sorting,
      rowSelection,
      // globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="rounded-md overflow-hidden">
        {isLoading ? (
          <Preloader />
        ) : (
          <Table className="border-separate border-spacing-y-4">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="text-[#D0D5DD] h-6">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                <>
                  {table.getRowModel().rows.filter((item) => item.original.status !== JobStatus.Done).length
                    ? table
                        .getRowModel()
                        .rows.filter((item) => item.original.status !== JobStatus.Done)
                        .map((row, i) => (
                          <JobContextMenu key={row.id} jobId={row.original._id}>
                            <TableRow
                              className={` bg-white hover:bg-white cursor-pointer`}
                              onClick={() => navigate(`/order/${row.original._id}`)}
                            >
                              {row.getVisibleCells().map((cell, j) => (
                                <TableCell
                                  key={cell.id}
                                  className={`border-none p-2 ${j === 0 ? "w-24" : ""} ${j === 2 ? "w-2/5" : ""} ${
                                    j === 0 ? "rounded-l-[61px]" : ""
                                  } ${j === row.getVisibleCells().length - 1 ? "rounded-r-[61px]" : ""}`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          </JobContextMenu>
                        ))
                    : " "}
                  {table.getRowModel().rows.filter((item) => item.original.status === JobStatus.Done).length ? (
                    <>
                      <TableRow key="divider" className={`hover:bg-transparent py-0`}>
                        <TableCell colSpan={7} className="p-2">
                          <div className="flex mx-12 items-center text-[#D0D5DD]">
                            <div className="h-[1px] bg-[#D0D5DD] w-1/2"></div>
                            <div className="px-8 whitespace-nowrap text-xs">Fertige Aufträge</div>
                            <div className="h-[1px] bg-[#D0D5DD] w-1/2"></div>
                          </div>
                        </TableCell>
                      </TableRow>
                      {table
                        .getRowModel()
                        .rows.filter((item) => item.original.status === JobStatus.Done)
                        .map((row, i) => (
                          <JobContextMenu key={row.id} jobId={row.original._id}>
                            <TableRow
                              className={`opacity-50 bg-white hover:bg-white cursor-pointer`}
                              onClick={() => navigate(`/order/${row.original._id}`)}
                            >
                              {row.getVisibleCells().map((cell, j) => (
                                <TableCell
                                  key={cell.id}
                                  className={`border-none p-2 ${j === 0 ? "w-24" : ""} ${j === 2 ? "w-2/5" : ""} ${
                                    j === 0 ? "rounded-l-[61px]" : ""
                                  } ${j === row.getVisibleCells().length - 1 ? "rounded-r-[61px]" : ""}`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          </JobContextMenu>
                        ))}
                    </>
                  ) : (
                    " "
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {getNoOrderText(currentQuery)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>

      <div className="flex items-center justify-between py-4 my-2">
        <Button
          className="text-[17px] text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          zurück
        </Button>
        <span className="text-sm text-[#D0D5DD]">
          Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
        </span>

        <Button
          className="text-[17px] text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          nächste
        </Button>
      </div>
      <ConfirmationDialog job={job} setReload={setReload} type={"internal"} setJob={setJob} />
    </div>
  );
};
