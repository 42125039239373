import { JobFilterSelectableEntities as CustomerJobFilterSelectableEntities } from "@/lib/interfaces/customers/jobs";
import { JobFilterSelectableEntities as InternalJobFilterSelectableEntities } from "@/lib/interfaces/internal/jobs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/use-user";
import { UserRole, UserType } from "../../lib/interfaces/user";
import AddIcon from "../../ui/components/icons/add-icon";
import FilterIcon from "../../ui/components/icons/filter-icon";
import { Button } from "../../ui/components/ui/button";
import DataFetchParent from "../../ui/components/ui/data-fetch";
import SearchInput from "../../ui/components/ui/search-input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/components/ui/select";
import CustomerJobFetchParent from "./customer/job-fetcher";
import InternalJobFetchParent from "./internal/job-fetcher";

const Order = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [customerSelectableEntities, setCustomerSelectableEntities] = useState<CustomerJobFilterSelectableEntities>();
  const [internalSelectableEntities, setInternalSelectableEntities] = useState<InternalJobFilterSelectableEntities>();
  const [initLoading, setInitLoading] = useState(true);
  const [resetPagination, setResetPagination] = useState(false);
  const cachedOrganisation = localStorage.getItem("cached-organisation");
  const { user } = useUser();

  useEffect(() => {
    if (customerSelectableEntities || internalSelectableEntities) {
      setInitLoading(false);
    }
  }, [customerSelectableEntities, internalSelectableEntities]);

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex justify-between">
        {user?.userType === UserType.Customer ? (
          <DataFetchParent
            isLoading={initLoading}
            data={customerSelectableEntities}
            renderElement={(data) => (
              <OrderCustomerFilters
                setSearchQuery={setSearchQuery}
                selectableEntities={data}
                setResetPagination={setResetPagination}
              />
            )}
          />
        ) : (
          <DataFetchParent
            isLoading={initLoading}
            data={internalSelectableEntities}
            renderElement={(data) => (
              <OrderInternalFilters
                setSearchQuery={setSearchQuery}
                selectableEntities={data}
                setResetPagination={setResetPagination}
                cachedOrganisation={cachedOrganisation ?? undefined}
              />
            )}
          />
        )}
        {user?.userType === UserType.Internal && user.userRole === UserRole.User ? (
          <></>
        ) : (
          <Link to="/order/create">
            <Button className="flex gap-4">
              <AddIcon />
              <span>Neuer Auftrag</span>
            </Button>
          </Link>
        )}
      </div>
      {user?.userType === UserType.Customer ? (
        <CustomerJobFetchParent
          searchText={searchQuery}
          setSelectableEntities={setCustomerSelectableEntities}
          resetPagination={resetPagination}
          setResetPagination={setResetPagination}
        />
      ) : (
        <InternalJobFetchParent
          searchText={searchQuery}
          setSelectableEntities={setInternalSelectableEntities}
          resetPagination={resetPagination}
          setResetPagination={setResetPagination}
          cachedOrganisation={cachedOrganisation ?? undefined}
        />
      )}
    </div>
  );
};

const OrderInternalFilters = ({
  setSearchQuery,
  selectableEntities,
  setResetPagination,
  cachedOrganisation,
}: {
  setSearchQuery: Dispatch<SetStateAction<string>>;
  selectableEntities: InternalJobFilterSelectableEntities;
  setResetPagination: Dispatch<SetStateAction<boolean>>;
  cachedOrganisation?: string;
}) => {
  const [newFilter, setNewFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const [doneFilter, setDoneFilter] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [currentOrganization, setCurrentOrganization] = useState<string>(
    selectableEntities.organizationsFilterUsed ?? cachedOrganisation
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    let filter = "";
    if (newFilter) {
      filter = "statusFilter=NEW";
    } else if (activeFilter) {
      filter = "statusFilter=ACTIVE";
    } else if (doneFilter) {
      filter = "statusFilter=DONE";
    } else {
      filter = "statusFilter=ALL";
    }

    if (currentOrganization) {
      filter += `&organizationId=${currentOrganization}`;
    }

    if (searchText) {
      filter += `&textSearch=${searchText}`;
    }

    setSearchQuery(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, searchText, newFilter, activeFilter, doneFilter, setSearchQuery]);

  return (
    <div className="flex items-center gap-4">
      <SearchInput setSearchText={setSearchText} />
      <div className="flex gap-3">
        <Button
          variant={doneFilter ? "default" : "outline"}
          onClick={() => {
            setDoneFilter((prev) => !prev);
            setNewFilter(false);
            setActiveFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={doneFilter} />
          Fertige Aufträge
        </Button>
        <Button
          variant={activeFilter ? "default" : "outline"}
          onClick={() => {
            setActiveFilter((prev) => !prev);
            setNewFilter(false);
            setDoneFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={activeFilter} />
          Offene Aufträge
        </Button>
        <Button
          variant={newFilter ? "default" : "outline"}
          onClick={() => {
            setNewFilter((prev) => !prev);
            setActiveFilter(false);
            setDoneFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={newFilter} />
          Neue Aufträge
        </Button>
      </div>
      <div className="relative">
        <Select
          onValueChange={(value) => {
            setCurrentOrganization(value);
            setResetPagination(true);
            localStorage.setItem("cached-organisation", value);
          }}
          defaultValue={currentOrganization}
        >
          <SelectTrigger className="flex bg-white focus:outline-none focus:ring-0 focus:ring-offset-0 items-center rounded-2xl pl-4 pr-4 py-2 border border-[#D0D5DD] text-[#888] text-sm gap-3">
            {/* <ChevronDown size="18" /> */}
            <SelectValue placeholder="Kunde" />
          </SelectTrigger>
          <SelectContent className="cursor-pointer">
            {selectableEntities?.organizations.map((user, i) => (
              <SelectItem
                key={user._id}
                value={user._id}
                className={`${user.isAssigned ? "bg-green-300 active:bg-green-300 hover:bg-green-200" : ""} ${
                  i === selectableEntities.organizations.length - 1 ? "" : "mb-1"
                }`}
              >
                {user.value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

const OrderCustomerFilters = ({
  setSearchQuery,
  selectableEntities,
  setResetPagination,
}: {
  setSearchQuery: Dispatch<SetStateAction<string>>;
  selectableEntities: CustomerJobFilterSelectableEntities;
  setResetPagination: Dispatch<SetStateAction<boolean>>;
}) => {
  const [newFilter, setNewFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const [doneFilter, setDoneFilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    let filter = "";
    if (newFilter) {
      filter = "statusFilter=NEW";
    } else if (activeFilter) {
      filter = "statusFilter=ACTIVE";
    } else if (doneFilter) {
      filter = "statusFilter=DONE";
    } else {
      filter = "statusFilter=ALL";
    }

    if (selectedUser) {
      filter += `&assignedCustomerUserId=${selectedUser}`;
    }

    if (searchText) {
      filter += `&textSearch=${searchText}`;
    }

    setSearchQuery(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, searchText, newFilter, activeFilter, doneFilter, setSearchQuery]);

  return (
    <div className="flex items-center gap-4">
      <SearchInput setSearchText={setSearchText} />
      <div className="flex gap-3">
        <Button
          variant={doneFilter ? "default" : "outline"}
          onClick={() => {
            setDoneFilter((prev) => !prev);
            setNewFilter(false);
            setActiveFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={doneFilter} />
          Fertige Aufträge
        </Button>
        <Button
          variant={activeFilter ? "default" : "outline"}
          onClick={() => {
            setActiveFilter((prev) => !prev);
            setNewFilter(false);
            setDoneFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={activeFilter} />
          Offene Aufträge
        </Button>
        <Button
          variant={newFilter ? "default" : "outline"}
          onClick={() => {
            setNewFilter((prev) => !prev);
            setActiveFilter(false);
            setDoneFilter(false);
          }}
          className="flex gap-3 items-center rounded-2xl px-3 py-2 border text-sm "
        >
          <FilterIcon active={newFilter} />
          Neue Aufträge
        </Button>
      </div>
      <div className="relative">
        <Select
          onValueChange={(value) => {
            setSelectedUser(value);
            setResetPagination(true);
          }}
        >
          <SelectTrigger className="flex bg-white focus:outline-none focus:ring-0 focus:ring-offset-0 items-center rounded-2xl pl-4 pr-4 py-2 border border-[#D0D5DD] text-[#888] text-sm gap-3">
            {/* <ChevronDown size="18" /> */}
            <SelectValue placeholder="Ansprechpartner" />
          </SelectTrigger>
          <SelectContent className="cursor-pointer">
            <SelectItem value="ALL">Alle Ansprechpartner</SelectItem>
            {selectableEntities?.customerUsers.map((user) => (
              <SelectItem key={user._id} value={user._id}>
                {user.value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export const getNoOrderText = (filterString: string) => {
  const match = filterString.match(/statusFilter=(\w+)/);
  const statusFilter = match ? match[1] : "ALL";
  switch (statusFilter) {
    case "NEW":
      return "Du hast noch keine neuen Aufträge";
    case "ACTIVE":
      return "Du hast noch keine offenen Aufträge";
    case "DONE":
      return "Du hast noch keine fertigen Aufträge";
    case "ALL":
      return "Du hast noch keine Aufträge";
    default:
      return "Du hast noch keine Aufträge";
  }
};

export default Order;
