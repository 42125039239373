import { JobListCustomerResponseDto } from "@/lib/interfaces/customers/jobs";
import { zodResolver } from "@hookform/resolvers/zod";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { LoaderCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import useApiRequest from "../../../../lib/hooks/useRequest";
import { convertHtmlToEditorState } from "../../../../lib/hooks/utils";
import AddIcon from "../../../../ui/components/icons/add-icon";
import { Button } from "../../../../ui/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../../ui/components/ui/form";
import { Input } from "../../../../ui/components/ui/input";
import CreateEditor from "../../create/create-editor";
import DataUpload from "../../create/data-upload";

// Define your Zod schema

export const CreateJob = () => {
  const { apiRequest } = useApiRequest();
  const [id, setId] = useState<string>();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(""));
  const [submitting, setSubmitting] = useState(false);

  const formSchema = z.object({
    name: z
      .string()
      .max(45, {
        message: "Der Name darf höchstens 45 Zeichen lang sein",
      })
      .min(1, {
        message: "Name ist erforderlich",
      }),
    description: z.string().refine(
      () => {
        const htmlContentProfile = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        return htmlContentProfile.length > 2;
      },
      { message: "Beschreibung ist erforderlich" }
    ),
  });

  type FormSchema = z.infer<typeof formSchema>;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const onSubmit = async (data: FormSchema) => {
    setSubmitting(true);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    const req = {
      title: data.name,
      description: htmlContent,
    };
    const res = await apiRequest<JobListCustomerResponseDto>("jobs/customer", "POST", {
      body: req,
      toast: {
        toastText: "Auftrag erfolgreich erstellt",
      },
    });
    if (res) {
      setId(res.data?._id);
    }
  };

  const onFilesUploaded = () => {
    setSubmitting(false);
    navigate(`/order/${id}`);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full">
        {/* <div className="flex flex-col gap-5 h-full"> */}
        <div className="flex justify-between">
          <div>
            <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">Neuer Auftrag</h1>
            <p className="text-[16px] text-[#888]">Erstelle einen neuen Auftrag</p>
          </div>
          <Button type="submit" className="flex gap-4" disabled={submitting}>
            {submitting ? <LoaderCircle className="animate-spin" /> : <AddIcon />}
            <span>Auftrag einreichen</span>
          </Button>
        </div>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
              <FormLabel className="text-[16px] font-bold text-[#12282A]">
                Name des Auftrages* <span className="font-normal text-[#888]">(max. 45 Zeichen)</span>
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Trage hier einen Namen für Deinen Auftrag ein."
                  className="w-full placeholder:text-[#888]"
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.value.length > 45) {
                      form.setError("name", {
                        type: "max",
                        message: "Der Name darf höchstens 45 Zeichen lang sein",
                      });
                    } else {
                      form.clearErrors("name");
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
              <FormLabel className="text-[16px] font-bold text-[#12282A]">Auftragsbeschreibung*</FormLabel>
              <div className="w-full">
                <FormControl>
                  <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <DataUpload type="customer" onFilesUploaded={onFilesUploaded} id={id} />
      </form>
    </Form>
  );
};
