import Preloader from "./preloader";

const DataFetchParent = ({
  isLoading,
  data,
  renderElement,
}: {
  isLoading: boolean;
  data: any;
  renderElement: (data: any) => React.ReactNode;
}) => {
  return <>{isLoading ? <Preloader /> : data ? renderElement(data) : <p>Es ist ein Fehler aufgetreten</p>}</>;
};

export default DataFetchParent;
