import {
  InternalInvitationListResponseDto,
  InternalUserListPaginatedResponseDtoWithInvitationCount,
  InternalUserListResponseDto,
} from "@/lib/interfaces/internal/users";
import { Paginated, Pagination } from "@/lib/interfaces/utils";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import useApiRequest from "../../../lib/hooks/useRequest";
import DataFetchParent from "../../../ui/components/ui/data-fetch";
import { InvitationDataTable } from "./invitation-data-table";
import { UserDataTable } from "./user-data-table";

const InternalUserFetchParent = ({
  showInvitations,
  setInvitationCount,
}: {
  showInvitations: boolean;
  setInvitationCount: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <>{showInvitations ? <InternalInvitedFetch /> : <InternalUserFetch setInvitationCount={setInvitationCount} />}</>
  );
};

const InternalInvitedFetch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [customers, setCustomers] = useState<InternalInvitationListResponseDto[]>();
  const [reload, setReload] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { apiRequest } = useApiRequest();

  const getCustomersPaginated = useCallback(async () => {
    let url = `users/internal/invitations?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`;
    setIsLoading(true);
    const res = await apiRequest<Paginated<InternalInvitationListResponseDto>>(url, "GET");
    setInitLoading(false);
    setIsLoading(false);
    if (res.data) {
      setCustomers(res.data.docs);
      setPageCount(Math.ceil(res.data.count / pagination.pageSize));
      setPageCount(res.data.pageCount);
    }
  }, [apiRequest, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    getCustomersPaginated();
  }, [pagination, getCustomersPaginated, reload]);

  return (
    <>
      <DataFetchParent
        isLoading={initLoading}
        data={customers}
        renderElement={() => (
          <InvitationDataTable
            data={customers ?? []}
            setReload={setReload}
            setPagination={setPagination}
            pageCount={pageCount}
            pagination={pagination}
            isLoading={isLoading}
          />
        )}
      />
    </>
  );
};

const InternalUserFetch = ({ setInvitationCount }: { setInvitationCount: Dispatch<SetStateAction<number>> }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [customers, setCustomers] = useState<InternalUserListResponseDto[]>();
  const [reload, setReload] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { apiRequest } = useApiRequest();

  const getCustomersInit = useCallback(async () => {
    setInitLoading(true);
    const res = await apiRequest<InternalUserListPaginatedResponseDtoWithInvitationCount>(
      `users/internal/initial?limit=${pagination.pageSize}`,
      "GET"
    );

    setInitLoading(false);
    if (res.data) {
      setCustomers(res.data.users.docs);
      setPageCount(Math.ceil(res.data.users.count / pagination.pageSize));
      setPageCount(res.data.users.pageCount);
      setInvitationCount(res.data.invitationCount);
    }
  }, [apiRequest, pagination.pageSize, setInvitationCount]);

  const getCustomersPaginated = useCallback(async () => {
    let url = `users/internal?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`;
    setIsLoading(true);
    const res = await apiRequest<Paginated<InternalUserListResponseDto>>(url, "GET");

    setIsLoading(false);
    if (res.data) {
      setCustomers(res.data.docs);
      setPageCount(Math.ceil(res.data.count / pagination.pageSize));
      setPageCount(res.data.pageCount);
    }
  }, [apiRequest, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    getCustomersInit();
  }, [getCustomersInit, reload]);

  useEffect(() => {
    if (!initLoading) {
      getCustomersPaginated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, getCustomersPaginated]);

  return (
    <>
      <DataFetchParent
        isLoading={initLoading}
        data={customers}
        renderElement={() => (
          <UserDataTable
            data={customers ?? []}
            setReload={setReload}
            setPagination={setPagination}
            pageCount={pageCount}
            pagination={pagination}
            isLoading={isLoading}
          />
        )}
      />
    </>
  );
};

export default InternalUserFetchParent;
