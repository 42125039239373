import { JobFileResponseDto } from "@/lib/interfaces/utils";
import { Download, FileType, Trash2 } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import useApiRequest from "../../lib/hooks/useRequest";
import { downloadURI } from "../../lib/hooks/utils";
import { Button } from "../../ui/components/ui/button";

const FileList = ({
  files,
  id,
  type,
  setReload,
}: {
  files: JobFileResponseDto[];
  id: string;
  type: "customer" | "internal";
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest } = useApiRequest();

  const deleteFile = async (file: JobFileResponseDto) => {
    await apiRequest(`jobs/${type}/${id}/files/${file.path}`, "DELETE", {
      toast: {
        toastText: "Datei wurde gelöscht",
      },
    });
    setReload((prev) => !prev);
  };

  const downloadFile = async (file: JobFileResponseDto) => {
    const res = await apiRequest<{
      fileUrl: string;
    }>(`jobs/${type}/${id}/files/${file.path}`, "GET", {
      toast: {
        toastText: "Datei wird heruntergeladen",
      },
    });
    if (res.data) {
      downloadURI(res.data.fileUrl, file.name);
    }
  };

  return (
    <div>
      <p className="text-[#12282A] font-bold">Dateien zum Auftrag</p>
      <div className="bg-white min-h-24 flex text-[#12282A] rounded-2xl pt-1">
        <div className="pt-2 flex flex-col w-full gap-2 items-center justify-between ">
          {files.map((file, i) => (
            <div
              key={i}
              className="flex justify-between items-center gap-3 rounded-lg px-3 py-2 border border-[#D0D5DD] text-sm mr-2 w-full"
            >
              <div className="flex gap-2 items-center">
                <FileType />
                <div className="flex flex-col text-sm justify-between">
                  <p className="font-medium">{file.name}</p>
                </div>
              </div>
              <div>
                <Button variant="link" className="text-[#12282A]" onClick={() => downloadFile(file)}>
                  <Download size={20} />
                </Button>
                <Button variant="link" className="text-red-500" onClick={() => deleteFile(file)}>
                  <Trash2 size={20} />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileList;
