import { ExternalLinkIcon } from "lucide-react";
import { ReactNode } from "react";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../../ui/components/ui/context-menu";

const JobContextMenu = ({ children, jobId }: { children: ReactNode; jobId: string }) => {
  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>{children}</ContextMenuTrigger>
      <ContextMenuContent className="w-64">
        <ContextMenuItem
          inset
          onClick={() => window.open(`/order/${jobId}`, "_blank")}
          className="flex justify-between"
        >
          <p>In neuem Tab öffnen</p>
          <ExternalLinkIcon className="w-4 h-4 ml-2" />
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default JobContextMenu;
