import { SquareArrowOutUpRight, Zap } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiRequest from "../../../../lib/hooks/useRequest";
import { convertHtmlToEditorState } from "../../../../lib/hooks/utils";
import { JobDetailsCustomerResponseDto } from "../../../../lib/interfaces/customers/jobs";
import { Button } from "../../../../ui/components/ui/button";
import DataFetchParent from "../../../../ui/components/ui/data-fetch";
import { StatusBar } from "../../[id]/page";
import Chat from "../../chat";
import CreateEditor from "../../create/create-editor";
import DataUpload from "../../create/data-upload";
import JobFileList from "../../file-list";

export const JobDetailsParent = ({ id }: { id: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<JobDetailsCustomerResponseDto>();
  const [reload, setReload] = useState(false);
  const { apiRequest } = useApiRequest();

  const fetchOrder = useCallback(async () => {
    setIsLoading(true);
    const res = await apiRequest<JobDetailsCustomerResponseDto>(`jobs/customer/${id}`, "GET");
    setIsLoading(false);
    if (res.data) {
      setOrder(res.data);
    }
  }, [apiRequest, id]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder, reload]);

  if (!id) {
    return <div>Organisation not found</div>;
  }

  return (
    <DataFetchParent
      data={order}
      renderElement={(data) => <OrderDetails orderId={id} setReload={setReload} order={data} />}
      isLoading={isLoading}
    />
  );
};

function OrderDetails({
  order,
  orderId,
  setReload,
}: {
  order: JobDetailsCustomerResponseDto;
  orderId: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}) {
  const { apiRequest } = useApiRequest();
  const [editorState] = useState(() => convertHtmlToEditorState(order.description));

  const reactivateOrder = async () => {
    const res = await apiRequest(`jobs/customer/${orderId}/reactivate`, "PATCH");
    if (res.data) {
      setReload((prev) => !prev);
    }
  };

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className="flex justify-between">
        <div>
          <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">
            {order.jobIncrementId} {order.title}
          </h1>
          <p className="text-[16px] text-[#888]">Ansprechpartner: {order.belongsToCustomerUserFullName}</p>
        </div>
        {order.selectableEntities.reactivateEnabled && (
          <Button className="flex gap-4" onClick={reactivateOrder}>
            <Zap />
            <span>Auftrag reaktivieren</span>
          </Button>
        )}
      </div>
      <StatusBar status={order.status} />
      <div className="flex gap-6 h-[70vh]">
        <div className="flex flex-col w-1/2 gap-4">
          {order.finalUrl && (
            <Link to={order.finalUrl} className="flex flex-col bg-[#12282A] rounded-[14px] p-6 gap-2" target="_blank">
              <p className="text-white font-bold">Link zu den finalen Dateien</p>
              <div className="bg-white px-5 py-2 rounded-2xl flex items-center justify-between">
                <input type="text" defaultValue={order.finalUrl} className="font-semibold outline-none" />
                <SquareArrowOutUpRight />
              </div>
            </Link>
          )}
          <div className="bg-white rounded-[14px] py-3 pr-3 flex-grow overflow-hidden">
            <div className="flex flex-col p-6 gap-3 custom-scrollbar h-full overflow-auto">
              <p className="text-[#12282A] font-bold">Auftragsbeschreibung</p>
              <div className="bg-white mb-8 rounded-2xl flex items-center justify-betweentext-[#888]">
                <div className="w-full resize-none custom-scrollbar pr-2 outline-none disabled:bg-white">
                  <CreateEditor disabled={true} editorState={editorState} />
                </div>
              </div>
              <JobFileList files={order.files} id={orderId} type="customer" setReload={setReload} />
            </div>
          </div>
        </div>
        <Chat type="customer" id={orderId} />
      </div>
      {order.filesAllowed && (
        <DataUpload
          type="customer"
          onFilesUploaded={() => setReload((prev) => !prev)}
          id={orderId}
          withUploadButton={true}
        />
      )}
    </div>
  );
}
