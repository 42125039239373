const AddIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M8.867 19A9.264 9.264 0 0 1 7.255 1.082M13.014 1a9.265 9.265 0 0 1 2.424 16.523M10.17 5.613v8.26M14.3 9.743H6.042" />
      </g>
    </svg>
  );
};

export default AddIcon;
