import {
  JobFilterSelectableEntities,
  JobListInternalListEntities,
  JobListInternalResponseDto,
  JobListPaginatedInternalResponseDtoWithEntities,
  JobListPaginatedInternalResponseDtoWithListEntities,
} from "@/lib/interfaces/internal/jobs";
import { Pagination } from "@/lib/interfaces/utils";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import useApiRequest from "../../../lib/hooks/useRequest";
import DataFetchParent from "../../../ui/components/ui/data-fetch";
import { InternalDataTable } from "./data-table";

const InternalJobFetchParent = ({
  searchText,
  setSelectableEntities,
  resetPagination,
  setResetPagination,
  cachedOrganisation,
}: {
  searchText: string;
  setSelectableEntities: Dispatch<SetStateAction<JobFilterSelectableEntities | undefined>>;
  resetPagination: boolean;
  setResetPagination: Dispatch<SetStateAction<boolean>>;
  cachedOrganisation?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [jobs, setJobs] = useState<JobListInternalResponseDto[]>();
  const [reload, setReload] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [listEntities, setListEntities] = useState<JobListInternalListEntities>();

  const { apiRequest } = useApiRequest();

  const getJobsInit = useCallback(async () => {
    setInitLoading(true);
    const res = await apiRequest<JobListPaginatedInternalResponseDtoWithEntities>(
      `jobs/internal/initial?limit=${pagination.pageSize}${
        cachedOrganisation ? `&organizationId=${cachedOrganisation}` : ""
      }`,
      "GET"
    );

    setInitLoading(false);
    if (res.data) {
      setJobs(res.data.jobs.docs);
      setPageCount(Math.ceil(res.data.jobs.count / pagination.pageSize));
      setPageCount(res.data.jobs.pageCount);
      setSelectableEntities(res.data.filterSelectableEntities);
      setListEntities(res.data.listEntities);
      localStorage.setItem("cached-organisation", res.data.filterSelectableEntities.organizationsFilterUsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest, pagination.pageSize, setSelectableEntities]);

  const getJobsPaginated = useCallback(async () => {
    let url = `jobs/internal?limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`;
    if (searchText) {
      url += `&${searchText}`;
    } else if (cachedOrganisation) {
      url += `&organizationId=${cachedOrganisation}`;
    }
    setIsLoading(true);
    const res = await apiRequest<JobListPaginatedInternalResponseDtoWithListEntities>(url, "GET");

    setIsLoading(false);
    if (res.data) {
      setJobs(res.data.jobs.docs);
      setPageCount(Math.ceil(res.data.jobs.count / pagination.pageSize));
      setPageCount(res.data.jobs.pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest, pagination.pageIndex, pagination.pageSize, searchText]);

  useEffect(() => {
    getJobsInit();
  }, [getJobsInit]);

  useEffect(() => {
    if (!initLoading) {
      getJobsPaginated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, getJobsPaginated, searchText, reload]);

  useEffect(() => {
    if (resetPagination) {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setResetPagination(false);
    }
  }, [resetPagination, setResetPagination]);

  return (
    <>
      <DataFetchParent
        isLoading={initLoading}
        data={listEntities}
        renderElement={(listEntities) => (
          <InternalDataTable
            data={jobs ?? []}
            setReload={setReload}
            setPagination={setPagination}
            pageCount={pageCount}
            pagination={pagination}
            isLoading={isLoading}
            listEntities={listEntities}
            currentQuery={searchText}
          />
        )}
      />
    </>
  );
};

export default InternalJobFetchParent;
