import { useUser } from "../../hooks/use-user";
import { UserType } from "../../lib/interfaces/user";

import { useEffect, useState } from "react";
import { Button } from "../../ui/components/ui/button";
import AddCustomerUserForm from "./customer/add-user";
import CustomerUserFetchParent from "./customer/user-fetcher";
import AddInternalUserFormParent from "./internal/add-user";
import InternalUserFetchParent from "./internal/user-fetcher";

const Customers = () => {
  const { user } = useUser();
  const [showInvitations, setShowInvitations] = useState(false);
  const [invitationCount, setInvitationCount] = useState(0);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    // rerender
  }, [reload]);

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex justify-between">
        <div></div>
        <div className="flex gap-3">
          <Button onClick={() => setShowInvitations(!showInvitations)} variant="outline">
            {showInvitations
              ? "Benutzer anzeigen"
              : invitationCount + (invitationCount === 1 ? " Einladung" : " Einladungen") + " anzeigen"}
          </Button>

          <>
            {user?.userType === UserType.Customer ? (
              <AddCustomerUserForm setReload={setReload} />
            ) : (
              <AddInternalUserFormParent setReload={setReload} />
            )}
          </>
        </div>
      </div>
      <>
        {user?.userType === UserType.Customer ? (
          <CustomerUserFetchParent showInvitations={showInvitations} setInvitationCount={setInvitationCount} />
        ) : (
          <InternalUserFetchParent showInvitations={showInvitations} setInvitationCount={setInvitationCount} />
        )}
      </>
    </div>
  );
};

export default Customers;
