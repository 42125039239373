const FingerPrintIcon = () => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.97199 17.5368C3.99074 17.5051 4.00995 17.4735 4.02962 17.442C5.46378 15.1493 6.29402 12.4379 6.29402 9.51845C6.29402 7.16614 8.19022 5.25922 10.5293 5.25922C12.8684 5.25922 14.7646 7.16614 14.7646 9.51845C14.7646 10.6014 14.6913 11.6678 14.5494 12.7129M12.3074 20C12.9551 18.6999 13.4861 17.3308 13.8866 15.9073M17.9514 17.1126C18.6344 14.6995 19 12.1519 19 9.51845C19 4.81384 15.2076 1 10.5294 1C8.98655 1 7.54003 1.41482 6.29412 2.13962M1 14.1654C1.67834 12.7612 2.05882 11.1845 2.05882 9.51845C2.05882 7.96687 2.47132 6.51218 3.19204 5.25922M10.5293 9.51845C10.5293 13.2636 9.46115 16.7578 7.61455 19.7099"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FingerPrintIcon;
