import { zodResolver } from "@hookform/resolvers/zod";
import { Mail, UserIcon } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../../lib/hooks/useRequest";
import AddIcon from "../../../ui/components/icons/add-icon";
import { Button } from "../../../ui/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";

const AddCustomerUserForm = ({ setReload }: { setReload: Dispatch<SetStateAction<boolean>> }) => {
  const { apiRequest } = useApiRequest();
  const [open, setOpen] = useState(false);

  const addCustomerSchema = z
    .object({
      firstname: z
        .string()
        .max(45, {
          message: "Der Name darf höchstens 45 Zeichen lang sein",
        })
        .min(1, {
          message: "Vorname ist erforderlich",
        }),
      lastname: z.string().min(1, {
        message: "Nachname ist erforderlich",
      }),
      email: z.string().email("Bitte geben Sie Ihre Email-Adresse ein"),
      emailRepeat: z.string().email("Bitte geben Sie Ihre Email-Adresse erneut ein"),
    })
    .refine((data) => data.email === data.emailRepeat, {
      message: "Die E-Mail-Adressen stimmen nicht überein",
      path: ["emailRepeat"],
    });

  type AddCustomerSchema = z.infer<typeof addCustomerSchema>;

  const form = useForm<AddCustomerSchema>({
    resolver: zodResolver(addCustomerSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      emailRepeat: "",
    },
  });

  const onSubmit = async (data: AddCustomerSchema) => {
    const req = {
      firstName: data.firstname,
      lastName: data.lastname,
      mail: data.email,
    };
    await apiRequest("users/customer/invitations", "POST", {
      body: req,
      toast: { toastText: "Benutzer wurde erfolgreich eingeladen" },
    });
    setOpen(false);
    setReload((prev) => !prev);
  };

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <Button className="gap-4" onClick={() => setOpen(true)}>
        <AddIcon />
        <span>Neuer Benutzer</span>
      </Button>
      <DialogContent className="sm:max-w-md min-w-[560px] py-4 px-6 gap-5">
        <DialogHeader className="mb-7">
          <DialogTitle className="flex items-center gap-3">
            <UserIcon />
            <p className="text-[25px] font-bold">Neuen Benutzer hinzufügen</p>
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full">
            <FormLabel className="text-[16px] font-bold text-[#12282A]">Name des Benutzers:*</FormLabel>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="firstname"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Vorname" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="lastname"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Nachname" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <FormLabel className="text-[16px] font-bold text-[#12282A]">E-Mail:*</FormLabel>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="E-Mail" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-1/2">
                {/* <Input placeholder="E-Mail wiederholen" /> */}
                <FormField
                  control={form.control}
                  name="emailRepeat"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="E-Mail wiederholen" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <DialogFooter className="sm:justify-start">
              {/* <DialogClose asChild>
                <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                  <Mail /> <span>Einladung senden</span>
                </Button>
              </DialogClose> */}
              <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                <Mail /> <span>Einladung senden</span>
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomerUserForm;
