import { UserSelectableEntityResponseDto } from "../user";
import { JobFileResponseDto, Paginated } from "../utils";

export enum JobStatus {
  Feedback = "FEEDBACK",
  New = "NEW",
  Active = "ACTIVE",
  Done = "DONE",
}

export enum JobFilterStatus {
  ALL = "ALL",
  New = "NEW",
  Active = "ACTIVE",
  Done = "DONE",
}

export interface JobListPaginatedCustomerResponseDtoWithListEntities {
  jobs: Paginated<JobListCustomerResponseDto>;
  listEntities: JobListCustomerListEntities;
}

export interface JobListCustomerListEntities {
  notificationJobIds: string[];
}

export interface JobListCustomerResponseDto {
  profilePictureBase64: string;
  belongsToCustomerUserFullName: string;
  _id: string;
  title: string;
  jobIncrementId: string;
  status: JobStatus;
  createdAt: string;
  isDeleteable: boolean;
  isSortable: boolean;
}

export interface JobListPaginatedCustomerResponseDtoWithEntities {
  jobs: Paginated<JobListCustomerResponseDto>;
  filterSelectableEntities: JobFilterSelectableEntities;
  listEntities: JobListCustomerListEntities;
}

export interface JobFilterSelectableEntities {
  customerUsers: UserSelectableEntityResponseDto[];
  customerUsersFilterUsed: string;
  statusFilter: JobFilterStatus;
}

export interface JobDetailsCustomerResponseDto {
  belongsToCustomerUserFullName: string;
  title: string;
  description: string;
  belongsToOrganizationName: string;
  jobIncrementId: string;
  finalUrl: string;
  status: JobStatus;
  dueDate: string;
  toFinishAt: string;
  position: number;
  endedAt: string;
  selectableEntities: JobSelectableEntityResponseDto;
  filesAllowed: boolean;
  files: JobFileResponseDto[];
}

export interface JobSelectableEntityResponseDto {
  reactivateEnabled: boolean;
}