export const parseEntity = (entity: string) => {
  switch (entity) {
    case "CompanyLocation":
      return "Standort hinzufügen";
    case "NoOrganizationForbidden":
      return "Organisation hinzufügen";
    case "NoUserFoundForbidden":
      return "Daten hinzufügen";
    case "InsufficientSubscriptionForbidden":
      return "Abonnement verwalten";
    case "Conflict":
      return "Konflikt";
    default:
      return entity;
  }
}