import { UserSelectableEntityResponseDto } from "../user";
import { JobFileResponseDto, Paginated } from "../utils";

export enum JobStatus {
  Feedback = "FEEDBACK",
  New = "NEW",
  Active = "ACTIVE",
  Done = "DONE",
}

export enum JobFilterStatus {
  ALL = "ALL",
  New = "NEW",
  Active = "ACTIVE",
  Done = "DONE",
}

export enum LogEvent {
  Created = "CREATED",
  ChangedStatus = "CHANGED_STATUS",
  ChangedInternalNotes = "CHANGED_INTERNAL_NOTES",
  LinkedFinalUrl = "LINKED_FINAL_URL",
  SentMessage = "SENT_MESSAGE",
  Deleted = "DELETED",
}

export interface JobListPaginatedInternalResponseDtoWithListEntities {
  jobs: Paginated<JobListInternalResponseDto>;
  listEntities: JobListInternalListEntities;
}

export interface JobListInternalListEntities {
  notificationJobIds: string[];
}

export interface JobListInternalResponseDto {
  profilePictureBase64: string;
  belongsToCustomerUserFullName: string;
  _id: string;
  title: string;
  jobIncrementId: string;
  status: JobStatus;
  createdAt: string;
  isDeleteable: boolean;
  belongsToOrganizationName: string;
}

export interface JobCreationEntitiesInternalResponseDto {
  organizations: OrganizationSelectableEntityResponseDto[];
}

export interface JobCreationEntitiesInternalResponseDtoWithFoundOrganization {
  customerUsers: UserSelectableEntityResponseDto[];
}

export interface OrganizationSelectableEntityResponseDto {
  _id: string;
  value: string;
}

export interface JobListPaginatedInternalResponseDtoWithEntities {
  jobs: Paginated<JobListInternalResponseDto>;
  filterSelectableEntities: JobFilterSelectableEntities;
  listEntities: JobListInternalListEntities;
}

export interface JobFilterSelectableEntities {
  organizations: OrganizationSelectableEntityWithAssignmentDetailResponseDto[];
  organizationsFilterUsed: string;
  statusFilter: JobFilterStatus;
}

export interface OrganizationSelectableEntityWithAssignmentDetailResponseDto {
  _id: string;
  value: string;
  isAssigned: boolean;
}

export interface JobDetailsInternalResponseDto {
  belongsToCustomerUserFullName: string;
  title: string;
  description: string;
  belongsToOrganizationName: string;
  jobIncrementId: string;
  finalUrl: string;
  status: JobStatus;
  internalNotes: string;
  dueDate: string;
  toFinishAt: string;
  position: number;
  endedAt: string;
  selectableEntities: JobSelectableEntityResponseDto;
  showLogs: boolean;
  filesAllowed: boolean;
  descriptionEditable: boolean;
  files: JobFileResponseDto[];
  needsDueDateAndMessageOnStatusChangeToActive: boolean;
}

export interface JobSelectableEntityResponseDto {
  status: JobStatus[];
}

export interface LogList {
  event: LogEvent;
  message: string;
  belongsToUserFullName: string;
  createdAt: string;
}
