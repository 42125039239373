import FileTypeIcon from "../icons/file-type-icon";

export enum FileTypeEnum {
  PDF = "pdf",
  SVG = "svg",
  PNG = "png",
  JPG = "jpg",
  GIF = "gif",
  MP4 = "mp4",
}

const FileType = ({ type }: { type: FileTypeEnum }) => {
  const getColor = (fileType: FileTypeEnum) => {
    switch (fileType) {
      case FileTypeEnum.MP4:
        return "text-[#2D20D9]";
      case FileTypeEnum.PDF:
        return "text-[#D92D20]";
      case FileTypeEnum.JPG:
        return "text-[#8418a5]";
      case FileTypeEnum.PNG:
        return "text-[#2DD920]";
      default:
        return "text-[#ffad41]";
    }
  };

  const color = getColor(type);

  return (
    <div className={`${color} relative`}>
      <FileTypeIcon />
      <span className="absolute bottom-1 left-1/2 transform -translate-x-1/2 text-[11px] font-semibold text-white">
        {type}
      </span>
    </div>
  );
};

export default FileType;
