import { EditorState } from "draft-js";
import { Dispatch, SetStateAction } from "react";
import { Editor } from "react-draft-wysiwyg";

const CreateEditor = ({
  editorState,
  setEditorState,
  disabled,
}: {
  editorState?: EditorState;
  setEditorState?: Dispatch<SetStateAction<EditorState>>;
  disabled?: boolean;
}) => {
  const defaultOptions = {
    options: ["inline", "blockType", "list", "textAlign", "link", "remove", "history"],
    inline: { options: ["bold", "italic", "underline", "strikethrough", "monospace"] },
    blockType: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    emoji: { inDropdown: true },
    remove: { inDropdown: true },
    history: { inDropdown: true },
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="settingsEditorWrapper"
      editorClassName="settingsEditorContent"
      toolbarClassName={disabled === undefined ? "" : disabled ? "settingsEditorToolbar" : ""}
      onEditorStateChange={setEditorState}
      readOnly={disabled === undefined ? false : disabled}
      toolbar={defaultOptions}
    />
  );
};

export default CreateEditor;
