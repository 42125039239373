import { useUser } from "../../../hooks/use-user";
import { UserType } from "../../../lib/interfaces/user";
import { CreateJob as CreateJobCustomer } from "../customer/create/create-job";
import { CreateJob as CreateJobInternal } from "../internal/create/create-job";

const CreateOrder = () => {
  const { user } = useUser();

  return <>{user?.userType === UserType.Customer ? <CreateJobCustomer /> : <CreateJobInternal />}</>;
};

export default CreateOrder;
