const ProblemSolvingIcon = () => {
  return (
    <svg width={23} height={20} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2634 7.50414V11.5041M11.2634 15.5041H11.2734M9.87875 2.39586L1.65386 16.6025C1.19765 17.3905 0.969552 17.7845 1.00326 18.1078C1.03267 18.3899 1.18044 18.6462 1.4098 18.8129C1.67276 19.0041 2.12802 19.0041 3.03854 19.0041H19.4883C20.3988 19.0041 20.8541 19.0041 21.1171 18.8129C21.3464 18.6462 21.4942 18.3899 21.5236 18.1078C21.5573 17.7845 21.3292 17.3905 20.873 16.6025L12.6481 2.39586C12.1935 1.61069 11.9663 1.21811 11.6697 1.08626C11.4111 0.971247 11.1158 0.971247 10.8571 1.08626C10.5606 1.21811 10.3333 1.6107 9.87875 2.39586Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProblemSolvingIcon;
