export enum UserRole {
  Admin = "ADMIN",
  User = "USER",
  SuperAdmin = "SUPERADMIN",
}

export enum UserType {
  Internal = "INTERNAL",
  Customer = "CUSTOMER",
}

export interface UserInformationResponseDto {
  userRole: UserRole;
  userType: UserType;
  profilePictureBase64: string;
}

export enum InvitationStatusEnum {
  INVITED = "eingeladen",
}

// export interface UserInfoResponseDto {
//   userType: UserTypeEnum;
//   userRole: UserRoleEnum;
// }

export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  mail: string;
  status: InvitationStatusEnum;
}

export interface UserSelectableEntityResponseDto {
  _id: string;
  value: string;
}

export interface UserProfileResponseDto {
  _id: string;
  firstName: string;
  lastName: string;
  mail: string;
  profilePictureBase64: string;
  appNotificationChatEnabled: boolean;
  appNotificationStatusEnabled: boolean;
  mailNotificationChatEnabled: boolean;
  mailNotificationStatusEnabled: boolean;
}