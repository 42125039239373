const RocketIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.359 8.333 4.667 6.641m1.692 1.692c.788-.3 1.544-.677 2.256-1.128M6.36 8.333v2.82s1.71-.31 2.256-1.127c.61-.914 0-2.82 0-2.82M4.667 6.64c.3-.779.678-1.525 1.128-2.228A7.266 7.266 0 0 1 12 1c0 1.534-.44 4.23-3.385 6.205m-3.948-.564h-2.82s.31-1.71 1.127-2.256c.914-.61 2.82 0 2.82 0M2.129 9.18C1.282 9.89 1 12 1 12s2.11-.282 2.82-1.128c.401-.474.395-1.202-.05-1.642a1.23 1.23 0 0 0-1.642-.05Z"
      />
    </svg>
  );
};

export default RocketIcon;
