const StarIcon = () => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.375 5.5V1.75M3.625 15.25V11.5M11.5 3.625H15.25M1.75 13.375H5.5M4.375 1L3.78666 2.17669C3.58754 2.57492 3.48798 2.77404 3.35498 2.94658C3.23696 3.09969 3.09969 3.23696 2.94658 3.35498C2.77404 3.48798 2.57492 3.58754 2.17669 3.78666L1 4.375L2.17669 4.96334C2.57492 5.16246 2.77404 5.26202 2.94659 5.39502C3.09969 5.51304 3.23696 5.65031 3.35498 5.80341C3.48798 5.97596 3.58754 6.17508 3.78666 6.57331L4.375 7.75L4.96334 6.57331C5.16246 6.17508 5.26202 5.97596 5.39502 5.80342C5.51304 5.65031 5.65031 5.51304 5.80342 5.39502C5.97596 5.26202 6.17508 5.16246 6.57331 4.96334L7.75 4.375L6.57331 3.78666C6.17508 3.58754 5.97596 3.48798 5.80341 3.35498C5.65031 3.23696 5.51304 3.09969 5.39502 2.94659C5.26202 2.77404 5.16246 2.57492 4.96334 2.17669L4.375 1ZM12.25 8.5L11.5367 9.92669C11.3375 10.3249 11.238 10.524 11.105 10.6966C10.987 10.8497 10.8497 10.987 10.6966 11.105C10.524 11.238 10.3249 11.3375 9.92669 11.5367L8.5 12.25L9.92669 12.9633C10.3249 13.1625 10.524 13.262 10.6966 13.395C10.8497 13.513 10.987 13.6503 11.105 13.8034C11.238 13.976 11.3375 14.1751 11.5367 14.5733L12.25 16L12.9633 14.5733C13.1625 14.1751 13.262 13.976 13.395 13.8034C13.513 13.6503 13.6503 13.513 13.8034 13.395C13.976 13.262 14.1751 13.1625 14.5733 12.9633L16 12.25L14.5733 11.5367C14.1751 11.3375 13.976 11.238 13.8034 11.105C13.6503 10.987 13.513 10.8497 13.395 10.6966C13.262 10.524 13.1625 10.3249 12.9633 9.92669L12.25 8.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
