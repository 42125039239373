import { InternalUserListResponseDto } from "@/lib/interfaces/internal/users";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateIcon } from "@radix-ui/react-icons";
import { UserIcon } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../../lib/hooks/useRequest";
import { Button } from "../../../ui/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";

const UserEditorDialog = ({
  user,
  setReload,
  onClose,
  endpoint,
}: {
  user: InternalUserListResponseDto;
  setReload: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  endpoint: string;
}) => {
  const { apiRequest } = useApiRequest();

  const addCustomerSchema = z.object({
    firstname: z
      .string()
      .max(45, {
        message: "Der Name darf höchstens 45 Zeichen lang sein",
      })
      .min(1, {
        message: "Vorname ist erforderlich",
      }),
    lastname: z.string().min(1, {
      message: "Nachname ist erforderlich",
    }),
    email: z.string().email("Bitte geben Sie Ihre Email-Adresse ein"),
  });

  type AddCustomerSchema = z.infer<typeof addCustomerSchema>;

  const form = useForm<AddCustomerSchema>({
    resolver: zodResolver(addCustomerSchema),
    defaultValues: {
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.mail,
    },
  });

  const onSubmit = async (data: AddCustomerSchema) => {
    const req = {
      firstName: data.firstname,
      lastName: data.lastname,
      mail: data.email,
    };
    await apiRequest(endpoint, "PATCH", {
      body: req,
      toast: { toastText: "Benutzer wurde erfolgreich aktualisiert" },
    });
    setReload((prev) => !prev);
    onClose();
  };

  return (
    <Dialog onOpenChange={() => onClose()} defaultOpen={true}>
      <DialogContent className="sm:max-w-md min-w-[560px] py-4 px-6 gap-5">
        <DialogHeader className="mb-7">
          <DialogTitle className="flex items-center gap-3">
            <UserIcon />
            <p className="text-[25px] font-bold">Nutzer bearbeiten</p>
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full">
            <FormLabel className="text-[16px] font-bold text-[#12282A]">Name des Benutzers:*</FormLabel>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="firstname"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Vorname" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="lastname"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Nachname" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <FormLabel className="text-[16px] font-bold text-[#12282A]">E-Mail:*</FormLabel>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="E-Mail" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <DialogFooter className="sm:justify-start">
              <DialogClose asChild>
                <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                  <UpdateIcon /> <span>User aktualisieren</span>
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UserEditorDialog;
